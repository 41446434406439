
import { defineComponent } from 'vue'
import Day from "@/components/Day.vue"
import { Day as DayClass } from "@/classes/Day"

export default defineComponent({
	components: { Day },
	data: () => {
		return {
			day: null as DayClass|null
		}
	},
	mounted() {
		this.day = new DayClass(new Date(), Number(this.$route.params.rate));
	}
})
