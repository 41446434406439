
import store from "@/store";
import { defineComponent } from "vue"

export default defineComponent({
	data: () => {
		return {
			anDur: .3,
			sunBeams: [] as Array<Array<string>>,
			splashIndex: 0
		}
	},
	computed: {
		theme: () => store.state.theme
	},
	watch: {
		theme: function() {
			this.splashIndex++;
		}
	},
	mounted() {
		let numOfBeams = 8,
			angle = 2*Math.PI / numOfBeams,
			beamStartRadius = 40,
			beamEndRadius = 50;
		for (let i=0; i<numOfBeams; i++) {
			let startX = 50 + beamStartRadius * Math.sin(angle*i),
			    startY = 50 + beamStartRadius * Math.cos(angle*i),
				endX   = 50 + beamEndRadius * Math.sin(angle*i),
			    endY   = 50 + beamEndRadius * Math.cos(angle*i);
			let arr = [
			    `M ${startX} ${startY} L ${startX} ${startY}`,
				`M ${startX} ${startY} L ${endX} ${endY}`
			];
			this.sunBeams.push(arr);
		}
	},
	methods: {
		splashClick(e:Event) {
			e.stopPropagation();
		}
	}
})
