import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30d51733"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date_wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "date"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["day", _ctx.classesArr])
  }, [
    _createElementVNode("div", {
      class: "day_inner",
      style: _normalizeStyle(_ctx.getStyle())
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.day?.date && _ctx.showDate)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.day.date.getDate()), 1))
        : _createCommentVNode("", true)
    ])
  ], 2))
}