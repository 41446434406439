import {Day as Day, IDay} from "./Day";
import {DayCollection, IDayCollection} from "./DayCollection";
import axios from 'axios';
import router from '@/router';
import store, { Theme } from '@/store'
import { ColorPalette, StandardColorPalettes, IRGBColor } from "@/classes/ColorPalette"

export interface IMonth extends IDayCollection {
	date: Date
}

export class Month extends DayCollection {
	private _date : Date = null as any;
	_palette : ColorPalette|null;

	set date(val: Date) {
		this._date = Month.fixDate(val);
	}
	get date() : Date {
		return this._date;
	}

	get palette():ColorPalette|null {
		return this._palette
	}
	set palette(newPalette:ColorPalette|null) {
		this._palette = newPalette;
		this.days.forEach((day) => { 
			day.palette = newPalette;
		});
	}

	constructor(date: Date, days: Array<Day> = [], palette:ColorPalette|null = null, theme:Theme|null = null) {
		super();
		this.days = days;
		this.date = date;
		this._palette = palette;
		if (this._palette) this._palette.localTheme = theme;
		this.days.forEach((day) => { 
			day.palette = this._palette;
		});
		if (this.getDaysInMonth() != this.days.length) this.fillGaps();
	}
	static fromObject(obj: IMonth, decryptNotes:Boolean=false):Month {
		let col = DayCollection.fromObject(obj, decryptNotes) as Month;
		return new Month(obj.date, col.days);
	}

	decryptNotes() {
		for(let i in this.days) this.days[i].decryptNote();
	}

	get name() : string {
		return store.getters.caption('months')[this.date.getMonth()];
	}
	get nameGenitive() : string {
		return store.getters.caption('monthsGenitive')[this.date.getMonth()];
	}

	get unixtime() : number {
		return this.date.getTime()/1000;
	}
	getDateOfMonthWithShift(shift: number) : Date {
		let d = new Date(this.date);
		d.setMonth(this.date.getMonth()+shift);
		return d;
	}
	getNextMonthDate() : Date {
		return this.getDateOfMonthWithShift(1);
	}
	getPrevMonthDate() : Date {
		return this.getDateOfMonthWithShift(-1);
	}
	
	// number of days in month
	getDaysInMonth() {
		return new Date(this.date.getFullYear(), this.date.getMonth()+1, 0).getDate();
	}

	getFirstDayOfWeek() {
		let fromSunday = new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay();
		let fromMonday = fromSunday == 0 ? 6 : fromSunday-1;
		return fromMonday;
	}
	
	static async load(date: Date, ratesOnly: Boolean = false) : Promise<Month> {
		store.state.loading = true;
		date = Month.fixDate(date);
		let firstDayDate = new Day(new Date(date.getFullYear(), date.getMonth(), 1)).toString('iso');
		let lastDayDate = new Day(new Date(date.getFullYear(), date.getMonth()+1, 0)).toString('iso');
		
		let col = await DayCollection.load({
			timeRange: JSON.stringify({
				start: firstDayDate,
				end: lastDayDate,
			}),
			ratesOnly: ratesOnly == false ? 0 : 1
		});

		return col == null ? new Month(date) : new Month(date, col.days);
	}

	static fixDate(date: Date) : Date {
		return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
	}

	toString():string {
		const isoString = this.date.toISOString();
		return isoString.slice(0, 7);
	}

	fillGaps() {
		for (let i=0; i<this.getDaysInMonth(); i++) {
			let dayTimestamp = this.date.getTime() + 24*3600*1000*i;
			if (this.days.find(d => d.date.getTime() == dayTimestamp) === undefined) {
				this.days.push(new Day(new Date(dayTimestamp), undefined, undefined, [], this.palette));
			}
		}
		this.days.sort((d1, d2) => d1.date.getTime() - d2.date.getTime());
	}

	containsDate(date:Date) {
		return (new Month(date)).date.getTime() == this.date.getTime();
	}

	clone():Month {
		return new Month(new Date(this._date), this.days.map((d) => d.clone()));
	}
}