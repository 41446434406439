
import { defineComponent } from 'vue';
import router from '@/router';
import store from '@/store';
import {Theme} from '@/store';
import LanguagePicker from "@/components/LanguagePicker.vue";
import Month from "@/components/Month.vue";
import Day from "@/components/Day.vue";
import DayNote from "@/components/DayNote.vue";
import MonthViewer from "@/components/MonthViewer.vue";
import PalettePicker from "@/components/PalettePicker.vue";
import LoginFrame from "@/components/LoginFrame.vue";
import { LoginFrameMode } from "@/components/LoginFrame.vue";
import {Day as DayClass} from "../classes/Day";
import {Month as MonthClass} from "../classes/Month";
import { MonthBuffer } from '@/classes/MonthBuffer';
import {Year} from "../classes/Year";
import { type Swiper as SwiperRef } from 'swiper';
import { ColorPalette, StandardColorPalettes, IRGBColor } from "@/classes/ColorPalette"
import { ColorPicker } from 'vue-accessible-color-picker'
import "@/assets/general.scss"

export default defineComponent({
	data() {
		return {
			titleDays: [
				new DayClass(new Date("2000-01-12"), 0.7),
				new DayClass(new Date("2000-01-13"), 0.85),
				new DayClass(new Date("2000-01-14"), 1),
			] as DayClass[],
			titleMonth: new MonthClass(
				new Date("2023-06-01"),
				[
					new DayClass(new Date("2023-06-02"), 0.9),
					new DayClass(new Date("2023-06-05"), 0.2),
					new DayClass(new Date("2023-06-08"), 0.8),
					new DayClass(new Date("2023-06-09"), 0.6),
					new DayClass(new Date("2023-06-18"), 0.75),
					new DayClass(new Date("2023-06-20"), 0.55),
					new DayClass(new Date("2023-06-22"), 0.7),
					new DayClass(new Date("2023-06-23"), 0.85),
					new DayClass(new Date("2023-06-24"), 1),
					new DayClass(new Date("2023-06-28"), 0.75)
				]
			),

			looksSectionShown: false,
			demoMonth: new MonthClass(
				new Date(`2023-${new Date().getMonth()+1}-01`),
				[
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-02`), 0.8, "После работы, пошёл гулять с друзьями по городу. Недавно случайно узнал код от домофона одной из многоэтажек, и предложил зайти и подняться на 23 этаж, чтобы посмотреть город с высоты птичьего полёта. Сначала они отказывались, но делать было особо нечего, и мы всё-таки пошли. Лифт поднимался непривычно долго (сам я живу на 6 этаже), но ожидание того стоило. Выйдя на балкон лесничной площадки, перед нами открылась вся красота вечернего города. Завораживающе и атмосферно. Мне больше всего понравилось наблюдать за потоками машин, расплывающихся от светофора. Постояли, пофоткали.", ["demo/road.jpg"]),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-07`), 1, "Свадьба лучшего друга. Церемония была красивая, хоть и скучноватая. После небольшого фуршета, начался роскошный банкет. Понравилась не только еда, но и то, как выглядел ресторан изнутри: светлый, просторный, с высокими потолками. В течение всего мероприятия, друг за другом выходили толкать речи близкие и дальние родственники, тост друзей же должен был быть ближе к концу. До этого времени был немного на нервяке, всё-таки тоже нужно было говорить речь, плюс мне предстояло спеть песню в качестве сюрприза. Когда настала наша очередь, всем, кажется, понравилась моя речь и исполненние песни. После этого - как камень с души, и весь оставшийся вечер прошёл как по маслу. Потом ещё гуляли с друзьями почти всю ночь по городу.", [{path: "demo/wedding2.jpg", attribution: 'Image by <a href="https://www.freepik.com/free-photo/people-having-some-drinks-beach-wedding_16515782.htm#query=wedding%20guests&position=22&from_view=search&track=ais" target="__blank">Freepik</a>'}]),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-10`), 0.6),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-11`), 0.733),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-12`), 0.866, "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium eaquia eveniet corrupti iste. Commodi aut dicta, cumque quinon perspiciatis magnam modi, deleniti porro laborum reprehenderit namiure voluptates fugiat autem officia repudiandae offic iissed, quassoluta nihil. Culpa vitaenam deleniti numquam odit libero, adquod molestias commodi, idamet, istesa optio obcaecati! Vitae modiiste nemo ducimus."),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-15`), 0.65, "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium ea quia eveniet corrupti iste. Commodi aut dicta, cumque qui non perspiciatis magnam modi, deleniti porro laborum reprehenderit nam iure voluptates fugiat autem officia repudiandae officiis sed, quas soluta nihil. Culpa vitae nam deleniti numquam odit libero, ad quod molestias commodi, id amet, iste optio obcaecati! Vitae modi iste nemo ducimus. Quaerat doloribus vitae saepe inventore mollitia tempora pariatur praesentium quasi earum, amet ducimus! Nihil et vero culpa id aliquam ipsam qui tempora, eos rem. Dolores pariatur dolore minima laborum laudantium sit nemo! Blanditiis sit quis ut dolores distinctio aut pariatur eius atque aperiam a, delectus asperiores accusamus vel magnam voluptas ea quia officiis?"),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-20`), 0.833, "Сходил на концерт любимой группы. Было мегакруто, понравилось, что играли не только хиты, но и менее известные, но не менее классные песни. Атмосфера была потрясная, хоть и не слишком много людей. Напрыгались и наорались под любимые песни вдоволь. Уже хочется снова.", [{path: "demo/concert.jpg", attribution: '<a href="https://www.freepik.com/free-photo/back-view-crowd-fans-watching-live-concert-performance-6_39993526.htm#query=rock%20concert&position=33&from_view=search&track=ais" target="__blank">Image by Vaynakh</a> on Freepik'}]),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-22`), 0.933, ""),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-24`), 0.966),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-26`), .45, "Мало спал, ещё и в пробке простоял целый час. На работе было довольно напряжно, сроки горят, все на нервах. Вечером пробки были не такие сильные, но усталость сделала своё дело, и время шло ещё медленнее, чем утром. Поужинал и завалился спать. Зато выспался."),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-27`), .9),
				]
			),
			comparedDay: new DayClass(new Date()),
			demoMonthTipShown: true,

			comparisonDays: [
				new DayClass(new Date(`2023-${new Date().getMonth()}-12`), 0.65, "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium eaquia eveniet corrupti iste. Commodi aut dicta, cumque quinon perspiciatis magnam modi, deleniti porro laborum reprehenderit namiure voluptates fugiat autem officia repudiandae offic iissed, quassoluta nihil. Culpa vitaenam deleniti numquam odit libero, adquod molestias commodi, idamet, istesa optio obcaecati! Vitae modiiste nemo ducimus."),
				new DayClass(new Date(`2023-${new Date().getMonth()}-10`), 0.866, "D suspendisse inest anten. Vulputate pharetra siamet. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla. Amet mattis vulputate enimnulla aliquet. Elit sedlputate misit. Duisapien eget miproin. Feugiat scelerisque varius morbienim. Et tortor consequat idporta nibh venenatis. Elementum nibhtellus molestie nuncnon. alesuada famesac turpis"),
				new DayClass(new Date(`2023-${new Date().getMonth()}-16`), 0.866, "Hendrerit gravida rutrum quisque non tellus orci. Eget nullam nonisi estsit ametfacilisis. Ipsum consequat nislvel pretium lectus quam. Mattis rhoncus urna neque viverra justonec ultrices duipien. Nuncnblandit massa enimdui. Platea dictumst vestibulum rhoncusest pellentesque. Malesuada pellentesque elieget gravida cumsociis. Purus sitamet luctus venenatis lectuagna fringilla. Duiut ornare lectussit"),
			],
			comparisonDayIndex: 0,
			cursorPlaced: false,
			cursorManipulationsInProccess: false,
			comparisonFinished: false,
			worksDayNoteMinHeight: window.innerHeight,
			
			paletteMonth: new MonthClass(
				new Date(`2023-${new Date().getMonth()+1}-01`),
				[
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-02`), 0.8),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-07`), 1),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-10`), 0.6),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-11`), 0.733),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-12`), 0.33, "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium eaquia eveniet corrupti iste. Commodi aut dicta, cumque quinon perspiciatis magnam modi, deleniti porro laborum reprehenderit namiure voluptates fugiat autem officia repudiandae offic iissed, quassoluta nihil. Culpa vitaenam deleniti numquam odit libero, adquod molestias commodi, idamet, istesa optio obcaecati! Vitae modiiste nemo ducimus."),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-15`), 0.65, "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Accusantium ea quia eveniet corrupti iste. Commodi aut dicta, cumque qui non perspiciatis magnam modi, deleniti porro laborum reprehenderit nam iure voluptates fugiat autem officia repudiandae officiis sed, quas soluta nihil. Culpa vitae nam deleniti numquam odit libero, ad quod molestias commodi, id amet, iste optio obcaecati! Vitae modi iste nemo ducimus. Quaerat doloribus vitae saepe inventore mollitia tempora pariatur praesentium quasi earum, amet ducimus! Nihil et vero culpa id aliquam ipsam qui tempora, eos rem. Dolores pariatur dolore minima laborum laudantium sit nemo! Blanditiis sit quis ut dolores distinctio aut pariatur eius atque aperiam a, delectus asperiores accusamus vel magnam voluptas ea quia officiis?"),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-20`), 0),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-22`), 0.933),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-24`), 0.966),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-26`), .45),
					new DayClass(new Date(`2023-${new Date().getMonth()+1}-27`), .9),
				],
				StandardColorPalettes["amber"],
				Theme.dark
			),
			standardColorPalettes: Object.values(StandardColorPalettes),
			paletteInnerClass: "",
			palettesInterval: 200,
			shownPaletteIndex: 0,

			loginFrameShown: false,
			LoginFrameMode,
			loginFrameMode: LoginFrameMode.login as LoginFrameMode,

			darkModeWasOn: false as Boolean
		}
	},
	components: { Day, Month, MonthViewer, DayNote, LoginFrame, LanguagePicker },
	computed: {},
	mounted() {
		if (router.currentRoute.value.name == "landingImg") router.replace("/");
		if (store.state.theme == 'dark') {
			document.body.classList.remove('dark_theme');
			store.commit("switchTheme");
			this.darkModeWasOn = true;
		}
	},
	methods: {
		titleScrollTrigger() {
			this.looksSectionShown = window.innerWidth <= 480 || window.scrollY > window.innerHeight * 0.8;
			document.body.style.setProperty("--page-scroll", (window.scrollY > 100 ? window.scrollY-100 : 0)+"");
			document.body.style.setProperty("--title-scrolled", (
				window.scrollY > (document.getElementsByClassName("title")[0] as HTMLElement).offsetHeight / 2 ? 1 : 0
			)+"");
		},
		looksMonthDaysMounted() {
			let days = document.querySelectorAll("section.looks .month .day");
			days.forEach((day) => (day as HTMLElement).style.animationDelay = (Math.random()*0.4+0.1)+"s");
		},
		performCursorManipulations(dayNoteEl:HTMLElement) {
			this.cursorManipulationsInProccess = true;

			let cursorEl = document.getElementById("cursor")!;
			let buttonIndex = Math.floor(Math.random()*1.5);
			let buttonEl = dayNoteEl.querySelectorAll(".comparison_buttons button")[buttonIndex] as HTMLElement;

			let bLeft  = buttonEl.getBoundingClientRect().left,
				bRight = buttonEl.getBoundingClientRect().right;
			let newTop = Math.random() * (buttonEl.getBoundingClientRect().bottom - buttonEl.getBoundingClientRect().top - 20) + buttonEl.getBoundingClientRect().top + 10;
			let newLeft = Math.random()*(bRight - bLeft - 40)*0.5 + bLeft + (bRight - bLeft - 40)*0.5*(1-buttonIndex) + 20;
			cursorEl.style.top = newTop + window.scrollY + "px";
			cursorEl.style.left = newLeft + "px";
			
			const nextNote = () => {
				cursorEl.style.setProperty("--trs", ".25s")
				cursorEl.style.top = (cursorEl.getBoundingClientRect().top + window.scrollY + Math.random() * 60 + 50) + "px";
				cursorEl.style.left = (cursorEl.getBoundingClientRect().left + Math.random() * 100 - 50) + "px";
				if (this.comparisonDayIndex != this.comparisonDays.length-1) this.comparisonDayIndex++;
				else this.comparisonFinished = true;
			}

			const clickButton = () => {
				buttonEl.classList.remove("hover_emulation");
				buttonEl.classList.add("click_emulation");
				setTimeout(nextNote, 50);
			}

			const waitCursorHover = () => {
				let hovers = [['top', 1], ['bottom', -1], ['left', 1], ['right', -1]].every(el => {
					let buttonCoord = buttonEl.getBoundingClientRect()[el[0] as 'top'|'bottom'|'left'|'right'];
					let cursorCoord = cursorEl.getBoundingClientRect()[el[0] == 'top' || el[0] == 'bottom' ? 'top' : 'left'];
					return buttonCoord*(el[1] as number) < cursorCoord*(el[1] as number);
				});

				if (!hovers) {
					setTimeout(waitCursorHover, 1000/30);
				}
				else {
					buttonEl.classList.add("hover_emulation");
					setTimeout(clickButton, 100)
				}
			}
			waitCursorHover();
		},
		palettesListItemClass(i:number) {
			if (i == this.shownPaletteIndex) return "palettes_list_item_active";
			if (i == this.shownPaletteIndex-1) return "palettes_list_item_prev palettes_list_item_neighbor";
			if (i == this.shownPaletteIndex-2) return "palettes_list_item_pre_prev";
			if (i == this.shownPaletteIndex+1) return "palettes_list_item_next palettes_list_item_neighbor";
			if (i == this.shownPaletteIndex+2) return "palettes_list_item_post_next";
		},
		reactToPaletteScroll() {
			let el = document.querySelector("section.palette")! as HTMLElement;
			let minTopPosPx = -3*16;
			let currTop = el.getBoundingClientRect().top;

			if (window.scrollY >= el.offsetTop - 200 || window.innerWidth <= 480) el.classList.add("shown");
			else el.classList.remove("shown");

			if (el.getBoundingClientRect().top <= minTopPosPx) {
				if (el.getBoundingClientRect().bottom > window.innerHeight + 80) {
					this.paletteInnerClass = "fixed";
					let newShownPaletteIndex = Math.min(Math.floor((minTopPosPx - currTop) / this.palettesInterval), this.standardColorPalettes.length-1);
					if (newShownPaletteIndex != this.shownPaletteIndex) {
						this.shownPaletteIndex = newShownPaletteIndex;
						this.paletteMonth.palette = this.standardColorPalettes[newShownPaletteIndex] as ColorPalette;
					}
				}
				else this.paletteInnerClass = "bottom";
			}
			else this.paletteInnerClass = "";
		},
		addPaletteScrollListeners() {
			window.addEventListener("scroll", this.reactToPaletteScroll, {
				capture: true,
				passive: false
			} as EventListenerOptions);
			window.addEventListener("resize", this.reactToPaletteScroll);
		},
		removePaletteScrollListeners() {
			window.removeEventListener("scroll", this.reactToPaletteScroll, {
				capture: true,
				passive: false
			} as EventListenerOptions);
			window.removeEventListener("resize", this.reactToPaletteScroll);
		},
		resetPaletteScrollListeners() {
			// this.removePaletteScrollListeners();
			// this.addPaletteScrollListeners();
		},
		openLoginFrame(mode:LoginFrameMode) {
			this.loginFrameMode = mode;
			this.loginFrameShown = true;
		}
	},
	beforeUnmount() {
		this.removePaletteScrollListeners();

		window.removeEventListener("scroll", this.titleScrollTrigger, {
			capture: true,
			passive: true
		} as EventListenerOptions);

		if (this.darkModeWasOn) {
			document.body.classList.add('dark_theme');
			store.commit("switchTheme");
		}
	},
	directives: {
		'title-month': {
			mounted(el, binding:any) {
				binding.instance.titleScrollTrigger();
				window.addEventListener("scroll", binding.instance.titleScrollTrigger, {
					capture: true,
					passive: true
				} as EventListenerOptions);
				setTimeout(() => el.classList.add("animation_done"), 1850)
			}
		},
		'month-viewer': {
			mounted(el) {
				el.style.minHeight = (el.querySelector(".month").offsetHeight + 100)+"px";
			}
		},
		"works-day-note": {
			mounted(el:HTMLElement, binding:any) {
				let compButtons = el.getElementsByClassName("comparison_buttons")[0] as HTMLElement;
				let elHeight = (el.offsetHeight - compButtons.offsetHeight*2) / 1.1;
				if (binding.instance.worksDayNoteMinHeight > elHeight)
					binding.instance.worksDayNoteMinHeight = elHeight;

				console.log(el.offsetHeight, el.offsetHeight - compButtons.offsetHeight);

				let p = el.getElementsByTagName("p")[0] as HTMLElement;
				p.innerHTML = "<div class='word'>" + p.innerHTML;
				p.innerHTML = p.innerHTML.replaceAll(/\s+/gi, "</div><div class='word'>");
				p.innerHTML+= "</div>";

				let cursorEl = document.getElementById("cursor")!;
				if (!binding.instance.cursorPlaced) {
					cursorEl.style.top = (el.getBoundingClientRect().bottom + window.scrollY + 50) + "px";
					cursorEl.style.left = "105%";
					cursorEl.classList.add("manipulated");
					binding.instance.cursorPlaced = true;
				}

				if (!binding.instance.cursorManipulationsInProccess) {
					window.addEventListener("scroll", (e) => {
						if (el.getBoundingClientRect().top > 500) return;
						if (binding.instance.cursorManipulationsInProccess) return;
						binding.instance.cursorManipulationsInProccess = true;
						setTimeout(() => binding.instance.performCursorManipulations(el), 500);
					});
					window.scrollBy({top: 1});
				}
				else {
					setTimeout(() => binding.instance.performCursorManipulations(el), 1500);
				}
			}
		},
		"comparison-done": {
			mounted(el, binding:any) {
				// return;
				let cursorEl = document.getElementById("cursor")!;
				let redoEl = el.getElementsByClassName("link_style")[0] as HTMLElement;

				let clickRedo = () => {
					redoEl.classList.remove("hover_emulation");
					redoEl.classList.add("click_emulation");
					setTimeout(() => {
						binding.instance.comparisonDayIndex = 0;
						binding.instance.comparisonFinished = false;
					}, 50);
				}

				let waitCursorHover = () => {
					let hovers = [['top', 1], ['bottom', -1], ['left', 1], ['right', -1]].every(el => {
						let buttonCoord = redoEl.getBoundingClientRect()[el[0] as 'top'|'bottom'|'left'|'right'];
						let cursorCoord = cursorEl.getBoundingClientRect()[el[0] == 'top' || el[0] == 'bottom' ? 'top' : 'left'];
						return buttonCoord*(el[1] as number) < cursorCoord*(el[1] as number);
					});

					if (!hovers) {
						setTimeout(waitCursorHover, 1000/30);
					}
					else {
						redoEl.classList.add("hover_emulation");
						setTimeout(clickRedo, 200)
					}
				}

				// анимация не запустится заново, если страница прокручена ниже.
				// сделал это из-за того, что какого-то хрена, при перезапуске, если страница прокручена ниже, она автоматом скроллится ещё ниже.
				// так и не понял из-за чего это происходит.

				let stopCursorMovement = false;

				let moveCursor = () => {
					if (stopCursorMovement) {
						setTimeout(moveCursor, 1000);
						return;
					}
					let newTop = Math.random() * (redoEl.getBoundingClientRect().bottom - redoEl.getBoundingClientRect().top - 20) + redoEl.getBoundingClientRect().top + 10;
					let newLeft = Math.random() * (redoEl.getBoundingClientRect().right - redoEl.getBoundingClientRect().left - 40) + redoEl.getBoundingClientRect().left + 20;
					cursorEl.style.top = newTop + window.scrollY + "px";
					cursorEl.style.left = newLeft + "px";
					waitCursorHover();
				}

				setTimeout(moveCursor, 3000);
				stopCursorMovement = el.getBoundingClientRect().bottom < 0;
				window.addEventListener("scroll", () => {
					stopCursorMovement = el.getBoundingClientRect().bottom < 0;
				});
			}
		},
		"palette": {
			mounted(el, binding:any) {
				binding.instance.reactToPaletteScroll();
				binding.instance.addPaletteScrollListeners();
			}
		}
	}
})
