
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import {Language} from '@/store'
import { defineComponent } from "vue"
import {Day} from "@/classes/Day"
import ThemeIcon from "@/components/ThemeIcon.vue"
import LanguagePicker from "@/components/LanguagePicker.vue"
import SecureLS from 'secure-ls'
import "@/assets/general.scss"

export default defineComponent({
	data: () => {
		return {
		}
	},
	components: { ThemeIcon, LanguagePicker },
	methods: {
		logout() {
			this.$store.state.loading = true;
			axios.get(store.state.apiRoot+"api/auth/logout.php")
			.then(res => {
				if (res.data.success) {
					this.$store.state.jwt = "";
					router.push({name : "login"});
					this.$store.state.loading = false;
					let ls = new SecureLS();
					ls.remove("encryptionKey");
				}
			});
		},
	}
})
