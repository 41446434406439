import axios from 'axios';
import {Day} from "../classes/Day";
import {Month} from "../classes/Month";

export default class Evaluator {
	day:Day;

	evaluationState = 0 as 0|1|2;  // 0 - subjective, 1 - objective, 2 - done
	estimate = null as -1|0|1|null;
	comparedDay = null as Day|null;
	evaluationRange = {
		start: null as number|null,
		end: null as number|null,
	};
	evaluationHistory = [] as {
		day: Day,
		evaluationRange: Object
	}[];
	lastComparison = null as 1|-1|null;
	worstDayCompared = false;
	bestDayCompared = false;
	loading = false as boolean;
	dateSent = "" as string

	constructor(day:Day) {
		this.day = day;
	}

	async initializeComparison(estimate: -1|0|1) {
		this.loading = true;
		this.estimate = estimate;
		this.comparedDay = await Day.load({ estimate: estimate, exclude: this.dateSent });
		if (this.comparedDay == null) {
			this.evaluationRange.start = 0.5+estimate*0.5;
			this.evaluationRange.end = 0.5+estimate*0.5;
			this.lastComparison = 1;
			this.finishEvaluation();
			return;
		}

		let startPromise = Day.load({ orderBy: "rate", limit: 1, exclude: this.dateSent });
		let endPromise	 = Day.load({ orderBy: "rate", orderRule: "desc", limit: 1, exclude: this.dateSent });
		Promise.all([startPromise, endPromise])
		.then(resultsArr => {
			this.evaluationRange.start = resultsArr[0]!.rate!;
			this.evaluationRange.end = resultsArr[1]!.rate!;
			this.evaluationState = 1;
			this.loading = false;
		});
	}
	
	backToSubjective() {
		this.evaluationState=0;
		this.estimate=null;
		this.comparedDay=null;
		this.evaluationRange = {
			start: null,
			end: null
		}
		this.lastComparison = null;
		this.worstDayCompared = false;
		this.bestDayCompared = false;
		this.evaluationHistory = [];
	}
	async dayCompared(comparison: -1|1) {
		this.loading = true;
		if (this.evaluationRange.start==null || this.evaluationRange.end==null || this.comparedDay==null) return;

		this.evaluationHistory.push({
			day: this.comparedDay,
			evaluationRange: {...this.evaluationRange}
		});

		this.lastComparison = comparison;
		let worstDayJustCompared = this.evaluationRange.start == this.comparedDay.rate;
		let bestDayJustCompared  = this.evaluationRange.end == this.comparedDay.rate;
		if (worstDayJustCompared) this.worstDayCompared=true;
		if (bestDayJustCompared) this.bestDayCompared=true;
		this.evaluationRange[comparison==1 ? "start" : "end"] = this.comparedDay.rate!;
		if (comparison == -1 && worstDayJustCompared || comparison == 1 && bestDayJustCompared) {
			this.loading = false;
			this.finishEvaluation();
			return;
		}

		let fetchedDay = await Day.load({
			centerOfRange: JSON.stringify(this.evaluationRange),
			lastComparison: comparison,
			worstChecked: this.worstDayCompared ? 1 : 0,
			bestChecked: this.bestDayCompared ? 1 : 0,
			exclude: this.dateSent
		});
		this.loading = false;
		if (fetchedDay != null && fetchedDay.rate != this.comparedDay!.rate) {
			this.comparedDay = fetchedDay;
		}
		else this.finishEvaluation();
	}
	
	finishEvaluation() {
		this.day.rate = (Number(this.evaluationRange.end!) + Number(this.evaluationRange.start!)) / 2;
		this.evaluationState = 2;
		this.loading = false;
	}

	undoEvaluationStep() {
		if (this.evaluationHistory.length==0) this.backToSubjective();
		else {
			this.comparedDay = this.evaluationHistory[this.evaluationHistory.length-1].day;
			this.evaluationRange = this.evaluationHistory[this.evaluationHistory.length-1].evaluationRange as any;
			if (this.evaluationState==2) {
				this.day.rate = undefined;
				this.evaluationState = 1;
			}
			this.evaluationHistory.pop();
		}
	}
}