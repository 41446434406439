
import { defineComponent } from "vue";
import store from '@/store';
import router from '@/router';
import axios from 'axios'
import "@/assets/general.scss";
import "@/assets/vars.scss";
import ThemeIcon from "@/components/ThemeIcon.vue";
import SecureLS from 'secure-ls'
import {Language} from '@/store'
import { ColorPalette, StandardColorPalettes, IRGBColor } from "@/classes/ColorPalette"

export default defineComponent({
	components: { ThemeIcon },
	data() {
		return {
			langPopupShown: false,
		}
	},
	beforeCreate() {
        document.getElementsByTagName('html')[0].classList.add('noscroll');
	},
	beforeUnmount() {
        document.getElementsByTagName('html')[0].classList.remove('noscroll');
	},
	methods: {
		logout() {
			this.$store.state.loading = true;
			axios.get(store.state.apiRoot+"api/auth/logout.php")
			.then(res => {
				if (res.data.success) {
					this.$store.state.jwt = "";
					router.push({name : "login"});
					this.$store.state.loading = false;
					let ls = new SecureLS();
					ls.remove("encryptionKey");
				}
			});
		},
		langArray() {
			return Object.values(Language);
		},
		langPicked(lang:Language) {
			store.commit('setLanguage', lang);
			this.langPopupShown = false;
		}
	}
})
