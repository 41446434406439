import {Month} from "@/classes/Month"
import axios from "axios"
import store from "@/store"
import {Theme} from "@/store"
import router from "@/router"
import SecureLS from 'secure-ls'
var CryptoJS = require("crypto-js");
import { ColorPalette, StandardColorPalettes, IRGBColor } from "@/classes/ColorPalette"

export interface IImageInfo {
	path: String,
	attribution?: String
}

export interface IDay {
	date: Date|string;  // string would be a date in iso format
	rate?: number | undefined;
	note?: String | undefined;
	img?: string | IImageInfo;
}

export class Day {
	private _date: Date = null as any;
	private _rate?: number|undefined;
	note?: String|undefined;
	img: Array<String|IImageInfo> = [];
	palette: ColorPalette|null;

	// to ensure the date is set without hours, minutes and seconds
	set date(val: Date) {
		this._date = new Date(val.getFullYear(), val.getMonth(), val.getDate(), 0, 0, 0)
	}
	get date(): Date {return this._date}

	set rate(val: number|undefined) {
		if (val == undefined) this._rate = undefined;
		else {
			if (val<0 || val>1) throw new RangeError("Rate of a day has to be between 0 and 1");
			else this._rate = val;
		}
	}
	get rate () { return this._rate }

	get imgPaths() : String[] {
		return this.img.map(obj => typeof obj == 'string' ? obj : (obj as IImageInfo).path);
	}

	constructor(date: Date, rate?: number, note?: String, img: Array<String|IImageInfo> = [], palette:ColorPalette|null = null, theme:Theme|null = null) {
		this.date = date;
		this.rate = rate;
		this.note = note;
		this.palette = palette;
		if (this.palette && theme) this.palette.localTheme = theme;
		if (img) this.img = img;
	}
	static fromObject(obj: IDay|null, decryptNote:Boolean=false) : Day|null {
		if (obj == null) return null;
		let day:Day;
		if (obj.date instanceof Date) day = new Day(obj.date, obj.rate, obj.note, !!obj.img ? JSON.parse(obj.img as string) : []);
		else day = new Day(Day.dateFromString(obj.date), obj.rate, obj.note, !!obj.img ? JSON.parse(obj.img as string) : [])

		if (decryptNote && !!day.note) {
			day.decryptNote();
		}
		return day;
	}

	decryptNote():void {
		let ls = new SecureLS();
		if (!!ls.get("encryptionKey")) {
			let bytes = CryptoJS.AES.decrypt(this.note, ls.get("encryptionKey"));
			this.note = bytes.toString(CryptoJS.enc.Utf8);
		}
		else throw Error("No decryption key set");
	}

	get color() {
		if (this.rate === undefined) return {r: undefined, g: undefined, b: undefined};
		return (this.palette ?? store.state.colorPalette).calcColor(this.rate);
	}
	get colorString() : string {
		return `rgb(${this.color.r}, ${this.color.g}, ${this.color.b})`;
		// return `hsl(${this.color.r}deg, ${this.color.g}%, ${this.color.b}%)`;
	}

	get isEmpty() : Boolean {
		return typeof this.rate == "undefined" && typeof this.note == "undefined";
	}
	static Empty(date:Date) : Day {
		return new Day(date, undefined, undefined);
	}

	toString(format:"iso"|"human"="human"):string {
		return Day.dateToString(this.date, format);
	}
	static dateFromString(str: string) {
		let d = new Date(Date.parse(str));
		d.setHours(0); d.setMinutes(0); d.setSeconds(0);
		return d;
	}
	static dateToString(date:Date, format:"iso"|"human"="human"):string {
		let methods = {
			"iso": () => {
				let dd = date.getDate() < 10 ? '0'+date.getDate() : date.getDate();
				let mm = date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1;
				let yyyy = date.getFullYear();
				return `${yyyy}-${mm}-${dd}`;
			},
			"human": () => {
				if (store.state.lang == "ru") return `${date.getDate()} ${store.getters.caption('monthsGenitive')[date.getMonth()]}`.toLowerCase();
				return `${store.getters.caption('monthsGenitive')[date.getMonth()]} ${date.getDate()}`
			}
		}
		return methods[format]();
	}

	static async load(params: Object = {}): Promise<Day|null> {
		store.state.loading = true;
		let returnVal = await axios.get(store.state.apiRoot+"api/days/", {
			params: params,
			headers: {
				"accept": "application/json",
				"Authorization": store.state.jwt as string,
			}
		}).then((res) => {
			// console.log(res.data);
			store.state.loading = false;
			if (res.data.status == 200) {
				if (!!res.data.jwt) store.state.jwt = res.data.jwt;
				if (!!res.data.day) return Day.fromObject(res.data.day, true);
				if (!!res.data.days) return Day.fromObject(res.data.days[0], true);
				return null;
			}
			else if (res.data.status == 403) {
				router.push({path: "/login"});
			}
			return null;
		});

		return returnVal;
	}

	// determines whether two or more days represent the same date
	static compare(...days: Array<Day|Date>):Boolean {
		let curDate, curMonth, curYear;
		for (let i=0; i<days.length; i++) {
			let date:Date;
			if (days[i] instanceof Day) date = (days[i] as Day).date;
			else date = days[i] as Date;
			if (i!=0) {
				if (date.getFullYear() != curYear || date.getMonth() != curMonth || date.getDate() != curDate) return false;
			}
			curYear = date.getFullYear(); curMonth = date.getMonth(); curDate = date.getDate();
		}
		return true;
	}

	clone():Day {
		return new Day(new Date(this.date), this.rate, this.note, this.img);
	}
}