import {Month} from "./Month"
import axios from "axios"

export class Year {
	months: Month[];
	year: number;

	private constructor(months: Month[], year:number) {
		this.months = months;
		this.year = year;
	}

	static async create(year:number):Promise<Year> {
		let promisesArr = [] as Array<Promise<Month>>;
		for (let i=0; i<12; i++) {
			promisesArr.push(Month.load(new Date(year, i)));
		}
		return Promise.all(promisesArr).then((resArr) => {
			return new Year(resArr, year);
		});
	}

	monthClones():Month[] {
		let res = [] as Month[];
		for (let m of this.months) {
			res.push(m.clone());
		}
		return res;
	}
}