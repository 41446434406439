
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import {Language} from '@/store'
import { defineComponent } from "vue"
import "@/assets/vars.scss"
import "@/assets/general.scss"

export default defineComponent({
	data() {
		return {
			langPopupShown: false,
		}
	},
	methods: {
		langArray() {
			return Object.values(Language);
		},
		langPicked(lang:Language) {
			store.commit('setLanguage', lang);
			this.langPopupShown = false;
		}
	}
})
