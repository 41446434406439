import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9482e2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inner_wrapper" }
const _hoisted_2 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Month = _resolveComponent("Month")!
  const _component_PalettePicker = _resolveComponent("PalettePicker")!
  const _directive_palette_picker = _resolveDirective("palette-picker")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Month, {
        month: (_ctx.sampleMonth as MonthClass),
        single: "",
        "hide-year": ""
      }, null, 8, ["month"])
    ]),
    _withDirectives(_createVNode(_component_PalettePicker, {
      class: "palette_picker_shown",
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.replace('/settings')))
    }, null, 512), [
      [_directive_palette_picker]
    ])
  ]))
}