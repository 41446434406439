import store from "@/store";
import {Theme} from "@/store";

export interface IRGBColor {
	r:number;
	g:number;
	b:number;
}

export class RGBColor {
	private _r:number;
	private _g:number;
	private _b:number;

	get r() { return this._r };
	get g() { return this._g };
	get b() { return this._b };
	set r(val) { this._r = this.validate(val) };
	set g(val) { this._g = this.validate(val) };
	set b(val) { this._b = this.validate(val) };

	private validate = (num:number) => num < 0 ? 0 : (num > 255 ? 255 : num);

	constructor(color:IRGBColor) {
		this._r = this.validate(color.r);
		this._g = this.validate(color.g);
		this._b = this.validate(color.b);
	}
}

type ThemedRGBColor = {
    [key in Theme]: IRGBColor;
}

export class ColorPalette {
	private _startColor: IRGBColor|ThemedRGBColor;
	private _endColor: IRGBColor|ThemedRGBColor;
	name: string;
	crossEmptyDays: Boolean;
	localTheme:Theme|null = null;

	get startColor():IRGBColor {
		return this.trueColor(this._startColor);
	}
	get endColor():IRGBColor {
		return this.trueColor(this._endColor);
	}
	set startColor(val: IRGBColor|ThemedRGBColor) {
		this._startColor = val;
	}
	set endColor(val: IRGBColor|ThemedRGBColor) {
		this._endColor = val;
	}

	constructor(sColor: IRGBColor|ThemedRGBColor, eColor: IRGBColor|ThemedRGBColor, name?:string, crossEmptyDays:boolean = false) {
		this._startColor = sColor;
		this._endColor = eColor;
		this.name = name ?? "";
		this.crossEmptyDays = crossEmptyDays;
	}
	static fromObject(obj:{_startColor: IRGBColor|ThemedRGBColor, _endColor: IRGBColor|ThemedRGBColor, name:string, crossEmptyDays:boolean} |
			{startColor: IRGBColor|ThemedRGBColor, endColor: IRGBColor|ThemedRGBColor, name:string, crossEmptyDays:boolean}):ColorPalette {
		return new ColorPalette((obj as any)._startColor ?? (obj as any).startColor, (obj as any)._endColor ?? (obj as any).endColor, obj.name, obj.crossEmptyDays);
	}

	gradientFunction(rate:number):number {
		return 2.095903275*Math.log10(2*rate+1);
	}

	private trueColor(color: IRGBColor|ThemedRGBColor) : IRGBColor {
		if ((color as ThemedRGBColor)["light"])
			return (color as ThemedRGBColor)[this.localTheme ?? store.state.theme];
		return color as IRGBColor; 
	}

	private calcColorComponent = (comp: "r"|"g"|"b", rate:number) => {
		return this.trueColor(this.startColor)[comp] + 
			(this.trueColor(this.endColor)[comp] - this.trueColor(this.startColor)[comp]) * this.gradientFunction(rate);
	}
	calcColor(rate:number):IRGBColor {
		if (rate > 1) rate = 1;
		if (rate < 0) rate = 0;
		return {
			r: this.calcColorComponent("r", rate),
			g: this.calcColorComponent("g", rate),
			b: this.calcColorComponent("b", rate)
		}
	}
	colorString(rate:number) : string {
		let color = this.calcColor(rate);
		return `rgb(${color.r}, ${color.g}, ${color.b})`;
	}
}

interface PaletteList {
    [key: string]: ColorPalette;
}
export const StandardColorPalettes:PaletteList = {
	amber: new ColorPalette(
		{
			"light": { r: 70, g: 50, b: 50 },
			"dark": { r: 20, g: 20, b: 20 },
		},
		{ r: 255, g: 191, b: 0 },
		"Amber",
	),
	tangerine: new ColorPalette(
		{ r: 40, g: 40, b: 35 },
		{ r: 255, g: 130, b: 20 },
		"Tangerine"
	),
	grass: new ColorPalette(
		{ r: 20, g: 30, b: 20 },
		{ r: 106, g: 255, b: 144 },
		"Grass"
	),
	arctic: new ColorPalette(
		{
			"light": { r: 50, g: 50, b: 70 },
			"dark": { r: 20, g: 20, b: 20 },
		},
		{ r: 174, g: 255, b: 240 },
		"Arctic"
	),
	alpine: new ColorPalette(
		{ r: 255, g: 50, b: 50 },
		{ r: 77, g: 172, b: 255 },
		"Alpine"
	),
	pale: new ColorPalette(
		{ r: 0, g: 0, b: 0 },
		{ r: 255, g: 255, b: 255 },
		"Pale",
		true
	),
}