<template>
	<div class="loading_icon"></div>
</template>
<script>
export default {
	
}
</script>
<style lang="scss" scoped>
.loading_icon {
	animation: loading .5s ease-out infinite;
	width: 50px;
	height: 50px;
	border: 2px solid rgba(255, 191, 0);
	border-radius: 25%;
}
@keyframes loading {
	0% {
		transform: 0;
	}
	100% {
		transform: rotate(180deg);
	}
}
</style>