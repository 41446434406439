import { Language } from "@/store"

export type CaptionElement = {[key in Language]: string|Array<string>}

export const captions:{[key: string]: CaptionElement} = 
{
	langName: {
		ru: "Русский",
		en: "English",
		es: "Español",
		fr: "Français",
		de: "Deutsch",
		it: "Italiano",
		pt: "Português",
		fi: "Suomi"
	},
	months: {
		ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		es: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		fr: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
		de: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
		it: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
		pt: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
		fi: ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"]
	},
	monthsShort: {
		ru: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
		en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		es: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
		fr: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
		de: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
		it: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
		pt: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
		fi: ['Tam', 'Hel', 'Maa', 'Huh', 'Tou', 'Kes', 'Hei', 'Elo', 'Syy', 'Lok', 'Mar', 'Jou']
	},
	monthsGenitive: {
		ru: ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября", "Декабря"],
		en: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		es: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
		fr: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
		de: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
		it: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
		pt: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
		fi: ["Tammikuuta", "Helmikuuta", "Maaliskuuta", "Huhtikuuta", "Toukokuuta", "Kesäkuuta", "Heinäkuuta", "Elokuuta", "Syyskuuta", "Lokakuuta", "Marraskuuta", "Joulukuuta"]
	},
	daysOfWeekShort: {
		ru: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],
		en: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
		es: ["LUN", "MAR", "MIÉ", "JUE", "VIE", "SÁB", "DOM"],
		fr: ["LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM"],
		de: ["MO", "DI", "MI", "DO", "FR", "SA", "SO"],
		it: ["LUN", "MAR", "MER", "GIO", "VEN", "SAB", "DOM"],
		pt: ["SEG", "TER", "QUA", "QUI", "SEX", "SÁB", "DOM"],
		fi: ["MA", "TI", "KE", "TO", "PE", "LA", "SU"]
	},
	daysOfWeekFull: {
		ru: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
		en: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
		es: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
		fr: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
		de: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
		it: ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"],
		pt: ["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"],
		fi: ["Maanantai", "Tiistai", "Keskiviikko", "Torstai", "Perjantai", "Lauantai", "Sunnuntai"]
	},
	navCalendar: {
		ru: "Календарь",
		en: "Calendar",
		es: "Calendario",
		fr: "Calendrier",
		de: "Kalender",
		it: "Calendario",
		pt: "Calendário",
		fi: "Kalenteri"
	},
	navAdd: {
		ru: "Добавить",
		en: "Add",
		es: "Añadir",
		fr: "Ajouter",
		de: "Hinzufügen",
		it: "Aggiungi",
		pt: "Adicionar",
		fi: "Lisää"
	},
	search: {
		ru: "Поиск",
		en: "Search",
		es: "Buscar",
		fr: "Rechercher",
		de: "Suche",
		it: "Ricerca",
		pt: "Pesquisar",
		fi: "Etsi"
	},
	logout: {
		ru: "Выйти",
		en: "Logout",
		es: "Cerrar sesión",
		fr: "Déconnexion",
		de: "Ausloggen",
		it: "Esci",
		pt: "Sair",
		fi: "Kirjaudu ulos"
	},
	edit: {
		ru: "Редактировать",
		en: "Edit",
		es: "Editar",
		fr: "Modifier",
		de: "Bearbeiten",
		it: "Modifica",
		pt: "Editar",
		fi: "Muokkaa"
	},
	noMemories: {
		ru: "Нет воспоминаний о дне",
		en: "No memories of this day",
		es: "No hay recuerdos de este día",
		fr: "Pas de souvenirs de cette journée",
		de: "Keine Erinnerungen an diesen Tag",
		it: "Nessun ricordo di questo giorno",
		pt: "Nenhuma lembrança deste dia",
		fi: "Ei muistoja tästä päivästä"
	},
	noPlans: {
		ru: "Нет планов на день",
		en: "No plans for this day",
		es: "No hay planes para este día",
		fr: "Pas de plans pour cette journée",
		de: "Keine Pläne für diesen Tag",
		it: "Nessun piano per questo giorno",
		pt: "Sem planos para este dia",
		fi: "Ei suunnitelmia tälle päivälle"
	},
	createDay: {
		ru: "Создать запись",
		en: "Add memories",
		es: "Agregar recuerdos",
		fr: "Ajouter des souvenirs",
		de: "Erinnerungen hinzufügen",
		it: "Aggiungi ricordi",
		pt: "Adicionar lembranças",
		fi: "Lisää muistoja"
	},
	showMore: {
		ru: "Показать полностью",
		en: "Show more",
		es: "Mostrar más",
		fr: "Voir plus",
		de: "Mehr anzeigen",
		it: "Mostra di più",
		pt: "Mostrar mais",
		fi: "Näytä enemmän"
	},
	today: {
		ru: "Сегодня",
		en: "Today",
		es: "Hoy",
		fr: "Aujourd'hui",
		de: "Heute",
		it: "Oggi",
		pt: "Hoje",
		fi: "Tänään"
	},
	yesterday: {
		ru: "Вчера",
		en: "Yesterday",
		es: "Ayer",
		fr: "Hier",
		de: "Gestern",
		it: "Ieri",
		pt: "Ontem",
		fi: "Eilen"
	},
	tomorrow: {
		ru: "Завтра",
		en: "Tomorrow",
		es: "Mañana",
		fr: "Demain",
		de: "Morgen",
		it: "Domani",
		pt: "Amanhã",
		fi: "Huomenna"
	},
	inCalendar: {
		ru: "В календаре",
		en: "In Calendar",
		es: "En el calendario",
		fr: "Dans le calendrier",
		de: "Im Kalender",
		it: "Nel calendario",
		pt: "No calendário",
		fi: "Kalenterissa"
	},
	initialDate: {
		ru: "Изначальная дата",
		en: "Initial Date",
		es: "Fecha inicial",
		fr: "Date initiale",
		de: "Anfangsdatum",
		it: "Data iniziale",
		pt: "Data inicial",
		fi: "Alkuperäinen päivä"
	},
	dayDescription: {
		ru: "Описание дня",
		en: "Day description",
		es: "Descripción del día",
		fr: "Description de la journée",
		de: "Tagesbeschreibung",
		it: "Descrizione del giorno",
		pt: "Descrição do dia",
		fi: "Päivän kuvaus"
	},
	eventsAndEmotions: {
		ru: "События и эмоции",
		en: "Events and Emotions",
		es: "Eventos y Emociones",
		fr: "Événements et émotions",
		de: "Veranstaltungen und Emotionen",
		it: "Eventi e Emozioni",
		pt: "Eventos e Emoções",
		fi: "Tapahtumat ja Tunteet"
	},
	dayWasMoreLike: {
		ru: "День был скорее...",
		en: "Day was rather...",
		es: "El día fue más como...",
		fr: "La journée était plus comme...",
		de: "Der Tag war eher wie...",
		it: "Il giorno era più simile a...",
		pt: "O dia foi mais parecido com...",
		fi: "Päivä oli enemmän kuin..."
	},
	good: {
		ru: "Хорошим",
		en: "Good",
		es: "Bueno",
		fr: "Bien",
		de: "Gut",
		it: "Buono",
		pt: "Bom",
		fi: "Hyvä"
	},
	bad: {
		ru: "Плохим",
		en: "Bad",
		es: "Malo",
		fr: "Mauvais",
		de: "Schlecht",
		it: "Male",
		pt: "Ruim",
		fi: "Huono"
	},
	hardToSay: {
		ru: "Трудно сказать",
		en: "Hard to say",
		es: "Difícil de decir",
		fr: "Difficile à dire",
		de: "Schwer zu sagen",
		it: "Difficile da dire",
		pt: "Difícil de dizer",
		fi: "Vaikea sanoa"
	},
	save: {
		ru: "Сохранить",
		en: "Save",
		es: "Guardar",
		fr: "Enregistrer",
		de: "Speichern",
		it: "Salva",
		pt: "Salvar",
		fi: "Tallenna"
	},
	goToToday: {
		ru: "Вернуться на сегодня",
		en: "Back to today",
		es: "Volver a hoy",
		fr: "Retour à aujourd'hui",
		de: "Zurück zu heute",
		it: "Torna ad oggi",
		pt: "Voltar para hoje",
		fi: "Takaisin tähän päivään"
	},
	better: {
		ru: "Лучше",
		en: "Better",
		es: "Mejor",
		fr: "Meilleur",
		de: "Besser",
		it: "Meglio",
		pt: "Melhor",
		fi: "Parannus"
	},
	worse: {
		ru: "Хуже",
		en: "Worse",
		es: "Peor",
		fr: "Pire",
		de: "Schlechter",
		it: "Peggio",
		pt: "Pior",
		fi: "Huonompi"
	},
	comparedToThisDay: {
		ru: "по сравнению с этим днём",
		en: "compared to this day",
		es: "comparado con este día",
		fr: "par rapport à cette journée",
		de: "im Vergleich zu diesem Tag",
		it: "rispetto a questo giorno",
		pt: "comparado com este dia",
		fi: "verrattuna tähän päivään"
	},
	deleteCannotBeUndone: {
		ru: "Удаление записи нельзя отменить",
		en: "Deletion cannot be undone",
		es: "La eliminación no se puede deshacer",
		fr: "La suppression ne peut pas être annulée",
		de: "Löschen kann nicht rückgängig gemacht werden",
		it: "L'eliminazione non può essere annullata",
		pt: "A exclusão não pode ser desfeita",
		fi: "Poistoa ei voi perua"
	},
	confirm: {
		ru: "Подтвердить",
		en: "Confirm",
		es: "Confirmar",
		fr: "Confirmer",
		de: "Bestätigen",
		it: "Conferma",
		pt: "Confirmar",
		fi: "Vahvista"
	},
	cancel: {
		ru: "Отмена",
		en: "Cancel",
		es: "Cancelar",
		fr: "Annuler",
		de: "Abbrechen",
		it: "Annulla",
		pt: "Cancelar",
		fi: "Peruuta"
	},
	startOver: {
		ru: "Начать заново",
		en: "Start over",
		es: "Empezar de nuevo",
		fr: "Recommencer",
		de: "Neu beginnen",
		it: "Ricomincia",
		pt: "Recomeçar",
		fi: "Aloita alusta"
	},
	comparisonWithPast: {
		ru: "Сравнение с прошлым",
		en: "Comparison with the past",
		es: "Comparación con el pasado",
		fr: "Comparaison avec le passé",
		de: "Vergleich mit der Vergangenheit",
		it: "Confronto con il passato",
		pt: "Comparação com o passado",
		fi: "Vertailu menneeseen"
	},
	ratingCompleted: {
		ru: "Оценка завершена",
		en: "Evaluation completed",
		es: "Evaluación completada",
		fr: "Évaluation terminée",
		de: "Bewertung abgeschlossen",
		it: "Valutazione completata",
		pt: "Avaliação concluída",
		fi: "Arviointi suoritettu"
	},
	cancelReevaluation: {
		ru: "Отменить переоценку",
		en: "Cancel reevaluation",
		es: "Cancelar reevaluación",
		fr: "Annuler la réévaluation",
		de: "Neubewertung abbrechen",
		it: "Annulla la rivalutazione",
		pt: "Cancelar reavaliação",
		fi: "Peruuta uudelleenarviointi"
	},
	photo: {
		ru: "Фото",
		en: "Photo",
		es: "Foto",
		fr: "Photo",
		de: "Foto",
		it: "Foto",
		pt: "Foto",
		fi: "Valokuva"
	},
	noMoreThan: {
		ru: "Не более",
		en: "No more than",
		es: "No más de",
		fr: "Pas plus de",
		de: "Nicht mehr als",
		it: "Non più di",
		pt: "Não mais que",
		fi: "Ei enempää kuin"
	},
	files: {
		ru: "файлов",
		en: "files",
		es: "archivos",
		fr: "fichiers",
		de: "Dateien",
		it: "file",
		pt: "arquivos",
		fi: "tiedostot"
	},
	login: {
		ru: "Вход",
		en: "Login",
		es: "Iniciar sesión",
		fr: "Connexion",
		de: "Anmeldung",
		it: "Accesso",
		pt: "Login",
		fi: "Kirjaudu sisään"
	},
	signup: {
		ru: "Регистрация",
		en: "Sign up",
		es: "Registrarse",
		fr: "S'inscrire",
		de: "Registrieren",
		it: "Iscriviti",
		pt: "Registre-se",
		fi: "Rekisteröidy"
	},
	email: {
		ru: "E-mail",
		en: "E-mail",
		es: "Correo electrónico",
		fr: "E-mail",
		de: "E-Mail",
		it: "E-mail",
		pt: "E-mail",
		fi: "Sähköposti"
	},
	password: {
		ru: "Пароль",
		en: "Password",
		es: "Contraseña",
		fr: "Mot de passe",
		de: "Passwort",
		it: "Password",
		pt: "Senha",
		fi: "Salasana"
	},
	loginButton: {
		ru: "Войти",
		en: "Login",
		es: "Iniciar sesión",
		fr: "Connexion",
		de: "Anmelden",
		it: "Accedi",
		pt: "Entrar",
		fi: "Kirjaudu sisään"
	},
	invalidCredentials: {
		ru: "Неверные данные",
		en: "Invalid credentials",
		es: "Credenciales no válidas",
		fr: "Informations d'identification invalides",
		de: "Ungültige Zugangsdaten",
		it: "Credenziali non valide",
		pt: "Credenciais inválidas",
		fi: "Virheelliset kirjautumistiedot"
	},
	continueButton: {
		ru: "Продолжить",
		en: "Continue",
		es: "Continuar",
		fr: "Continuer",
		de: "Fortsetzen",
		it: "Continua",
		pt: "Continuar",
		fi: "Jatka"
	},
	userExistsMessage: {
		ru: "Пользователь с таким E-mail уже существует",
		en: "User with this E-mail already exists",
		es: "El usuario con este correo electrónico ya existe",
		fr: "Un utilisateur avec cette adresse e-mail existe déjà",
		de: "Benutzer mit dieser E-Mail-Adresse existiert bereits",
		it: "Utente con questa email già esistente",
		pt: "Usuário com este E-mail já existe",
		fi: "Käyttäjä tällä sähköpostilla on jo olemassa"
	},
	registrationError: {
		ru: "Ошибка при регистрации",
		en: "Error during registration",
		es: "Error durante el registro",
		fr: "Erreur lors de l'inscription",
		de: "Fehler bei der Registrierung",
		it: "Errore durante la registrazione",
		pt: "Erro durante o registro",
		fi: "Virhe rekisteröityessä"
	},
	secretQuestions: {
		ru: "Напишите ответ на один из секретных вопросов. Он понадобится, если вы забудете пароль.",
		en: "Write an answer to one of the secret questions. It will be needed if you forget your password.",
		es: "Escriba una respuesta a una de las preguntas secretas. Se necesitará si olvida su contraseña.",
		fr: "Écrivez une réponse à l'une des questions secrètes. Elle sera nécessaire si vous oubliez votre mot de passe.",
		de: "Schreiben Sie eine Antwort auf eine der geheimen Fragen. Sie wird benötigt, wenn Sie Ihr Passwort vergessen.",
		it: "Scrivi una risposta a una delle domande segrete. Sarà necessaria nel caso in cui dimentichi la tua password.",
		pt: "Escreva uma resposta para uma das perguntas secretas. Será necessária se você esquecer sua senha.",
		fi: "Kirjoita vastaus yhteen salaisista kysymyksistä. Tarvitaan, jos unohdat salasanasi."
	},
	selectQuestion: {
		ru: "--выберите вопрос--",
		en: "--select a question--",
		es: "--seleccione una pregunta--",
		fr: "--sélectionnez une question--",
		de: "--wähle eine Frage--",
		it: "--seleziona una domanda--",
		pt: "--selecione uma pergunta--",
		fi: "--valitse kysymys--"
	},
	firstBookQuestion: {
		ru: "Ваша первая книга?",
		en: "Your first book?",
		es: "¿Tu primer libro?",
		fr: "Votre premier livre?",
		de: "Dein erstes Buch?",
		it: "Il tuo primo libro?",
		pt: "Seu primeiro livro?",
		fi: "Ensimmäinen kirjasi?"
	},
	firstComputerGameQuestion: {
		ru: "Ваша первая компьютерная игра?",
		en: "Your first computer game?",
		es: "Tu primer juego de computadora?",
		fr: "Votre premier jeu vidéo?",
		de: "Dein erstes Computerspiel?",
		it: "Il tuo primo gioco per computer?",
		pt: "Seu primeiro jogo de computador?",
		fi: "Ensimmäinen tietokonepelaamasi peli?"
	},
	firstDateQuestion: {
		ru: "Где проходило ваше первое свидание?",
		en: "Where was your first date?",
		es: "¿Dónde fue tu primera cita?",
		fr: "Où a eu lieu votre première rencontre?",
		de: "Wo war dein erstes Date?",
		it: "Dove è stata la tua prima data?",
		pt: "Onde foi seu primeiro encontro?",
		fi: "Missä oli ensimmäinen treffeistäsi?"
	},
	firstFriendNameQuestion: {
		ru: "Имя вашего самого первого друга?",
		en: "Name of your very first friend?",
		es: "Nombre de tu primer amigo?",
		fr: "Nom de votre tout premier ami?",
		de: "Name deines allerersten Freundes?",
		it: "Nome del tuo primo amico?",
		pt: "Nome do seu primeiro amigo?",
		fi: "Ensimmäisen ystäväsi nimi?"
	},
	answer: {
		ru: "Ответ",
		en: "Answer",
		es: "Respuesta",
		fr: "Réponse",
		de: "Antwort",
		it: "Risposta",
		pt: "Resposta",
		fi: "Vastaus"
	},
	registerButton: {
		ru: "Зарегистироваться",
		en: "Signup",
		es: "Regístrate",
		fr: "S'inscrire",
		de: "Registrieren",
		it: "Iscriviti",
		pt: "Registrar",
		fi: "Rekisteröidy"
	},
	noAccountTip: {
		ru: "Ещё нет аккаунта?",
		en: "Don't have an account yet?",
		es: "¿Aún no tienes una cuenta?",
		fr: "Vous n'avez pas encore de compte ?",
		de: "Sie haben noch kein Konto?",
		it: "Non hai ancora un account?",
		pt: "Ainda não tem uma conta?",
		fi: "Ei vielä tiliä?"
	},
	signUpLink: {
		ru: "Зарегистрируйтесь",
		en: "Sign up",
		es: "Regístrate",
		fr: "S'inscrire",
		de: "Registrieren",
		it: "Iscriviti",
		pt: "Registre-se",
		fi: "Luo tili"
	},
	haveAccountTip: {
		ru: "Уже есть аккаунт?",
		en: "Already have an account?",
		es: "¿Ya tienes una cuenta?",
		fr: "Vous avez déjà un compte ?",
		de: "Sie haben bereits ein Konto?",
		it: "Hai già un account?",
		pt: "Já tem uma conta?",
		fi: "Onko sinulla jo tili?"
	},
	loginLink: {
		ru: "Войдите",
		en: "Login",
		es: "Iniciar sesión",
		fr: "Se connecter",
		de: "Anmelden",
		it: "Accedi",
		pt: "Entrar",
		fi: "Kirjaudu sisään"
	},
	goBackTip: {
		ru: "Вернуться назад",
		en: "Go back",
		es: "Volver",
		fr: "Retourner en arrière",
		de: "Zurück gehen",
		it: "Torna indietro",
		pt: "Voltar",
		fi: "Palaa takaisin"
	},
	keywordsOrPhrase: {
		ru: "Ключевые слова или фраза",
		en: "Keywords or Phrase",
		es: "Palabras clave o frase",
		fr: "Mots clés ou phrase",
		de: "Schlüsselwörter oder Satz",
		it: "Parole chiave o frase",
		pt: "Palavras-chave ou frase",
		fi: "Avainsanat tai lause"
	},
	searchButton: {
		ru: "Найти",
		en: "Find",
		es: "Encontrar",
		fr: "Trouver",
		de: "Finden",
		it: "Trova",
		pt: "Encontrar",
		fi: "Etsi"
	},
	settingsTheme: {
		ru: "Тема",
		en: "Theme",
		es: "Tema",
		fr: "Thème",
		de: "Thema",
		it: "Tema",
		pt: "Tema",
		fi: "Teema"
	},
	settingsLanguage: {
		ru: "Язык",
		en: "Language",
		es: "Idioma",
		fr: "Langue",
		de: "Sprache",
		it: "Lingua",
		pt: "Idioma",
		fi: "Kieli"
	},
	month: {
		ru: "Месяц",
		en: "Month",
		es: "Mes",
		fr: "Mois",
		de: "Monat",
		it: "Mese",
		pt: "Mês",
		fi: "Kuukausi"
	  },
	year: {
		ru: "Год",
		en: "Year",
		es: "Año",
		fr: "Année",
		de: "Jahr",
		it: "Anno",
		pt: "Ano",
		fi: "Vuosi"
	},
	colour: {
		ru: "Цвет",
		en: "Color",
		es: "Color",
		fr: "Couleur",
		de: "Farbe",
		it: "Colore",
		pt: "Cor",
		fi: "Väri"
	},
	palette: {
		ru: "Палитра",
		en: "Palette",
		es: "Paleta",
		fr: "Palette",
		de: "Palette",
		it: "Palette",
		pt: "Paleta",
		fi: "Värimaailma"
	},
	worst: {
		ru: "Худший",
		en: "Worst",
		es: "Peor",
		fr: "Pire",
		de: "Schlimmste",
		it: "Peggiore",
		pt: "Pior",
		fi: "Huonoin"
	},
	best: {
		ru: "Лучший",
		en: "Best",
		es: "Mejor",
		fr: "Meilleur",
		de: "Beste",
		it: "Migliore",
		pt: "Melhor",
		fi: "Paras"
	},
	custom: {
	  	ru: "Кастом",
	  	en: "Custom",
	  	es: "Personalizado",
	  	fr: "Personnalisé",
	  	de: "Einstellen",
	  	it: "Сonfigurare",
	  	pt: "Personalizado",
	  	fi: "Mukautettu",
	},
	standard: {
	  	ru: "Стандарт",
	  	en: "Standard",
	  	es: "Estándar",
	  	fr: "Standard",
	  	de: "Standard",
	  	it: "Standard",
	  	pt: "Padrão",
	  	fi: "Standardi",
	},
	landingTitleCaptionHead: {
		ru: "Какого цвета твой день?",
		en: "What color is your day?",
		es: "¿De qué color es tu día?",
		fr: "Quelle couleur est ta journée?",
		de: "Welche Farbe hat dein Tag?",
		it: "Di che colore è il tuo giorno?",
		pt: "De que cor é o seu dia?",
		fi: "Minkä värinen on päiväsi?"
	},
	landingTitleCaptionSubhead: {
		ru: "Открой новый взгляд на воспоминания",
		en: "Discover a new perspective on memories",
		es: "Descubre una nueva perspectiva sobre los recuerdos",
		fr: "Découvrez un nouveau regard sur les souvenirs",
		de: "Entdecke eine neue Perspektive auf Erinnerungen",
		it: "Scopri una nuova prospettiva sui ricordi",
		pt: "Descubra uma nova perspectiva sobre memórias",
		fi: "Avaa uusi näkökulma muistoihin"
	},
	landingCaptionButtonSignup: {
		ru: "Хочу узнать",
		en: "Wanna know",
		es: "Quiero saber",
		fr: "Je veux savoir",
		de: "Ich möchte wissen",
		it: "Voglio sapere",
		pt: "Eu quero saber",
		fi: "Haluan tietää"
	},
	landingCaptionButtonLogin: {
		ru: "Уже есть профиль",
		en: "I have a profile",
		es: "Ya tengo un perfil",
		fr: "J'ai déjà un profil",
		de: "Habe bereits ein Profil",
		it: "Ho già un profilo",
		pt: "Já tenho um perfil",
		fi: "Minulla on jo profiili"
	},
	landingCaptionButtonCalendar: {
		ru: "Открыть календарь",
		en: "Open the calendar",
		es: "Al calendario",
		fr: "Au calendrier",
		de: "Zum Kalender",
		it: "Al calendario",
		pt: "Para o calendário",
		fi: "Kalenteriin"
	},
	landingLooksHead: {
		ru: "Как выглядит месяц в AmberDay",
		en: "What does a month look like in AmberDay",
		es: "¿Cómo se ve un mes en AmberDay?",
		fr: "A quoi ressemble un mois dans AmberDay",
		de: "Wie sieht ein Monat in AmberDay aus?",
		it: "Come appare un mese in AmberDay",
		pt: "Como é um mês no AmberDay",
		fi: "Miltä kuukausi näyttää AmberDayssa"
	},
	landingLooksSubhead: {
		ru: "Нажмите на день чтобы увидеть заметку",
		en: "Click on a day to see the note",
		es: "Haz clic en un día para ver la nota",
		fr: "Cliquez sur un jour pour voir la note",
		de: "Klicken Sie auf einen Tag, um die Notiz zu sehen",
		it: "Clicca su un giorno per vedere la nota",
		pt: "Clique em um dia para ver a nota",
		fi: "Napsauta päivää nähdäksesi huomautuksen"
	},
	landingWorksHead: {
		ru: "Как это работает",
		en: "How it works",
		es: "Cómo funciona",
		fr: "Comment ça marche",
		de: "Wie es funktioniert",
		it: "Come funziona",
		pt: "Como funciona",
		fi: "Miten se toimii"
	},
	landingWorksParagraphs: {
		ru: [
		  	"В AmberDay каждый день получает свой уникальный оттенок в зависимости от того, насколько этот день был хорошим",
		  	"Для определения цвета, вы сравниваете новый день с прошедшими",
		  	"Благодаря выстроенному алгоритму, количество сравнений останется небольшим, даже когда дней станет много"
		],
		en: [
		  	"In AmberDay, each day receives its unique shade depending on how good or bad it was",
		  	"To determine the color, you compare a new day with the previous ones",
		  	"Thanks to the used algorithm, the number of comparisons remains small even when there are many days"
		],
		es: [
		  	"En AmberDay, cada día recibe su tono único dependiendo de lo bueno o malo que haya sido ese día",
		  	"Para determinar el color, comparas un nuevo día con los anteriores",
		  	"Gracias al algoritmo estructurado, el número de comparaciones se mantiene pequeño incluso cuando hay muchos días"
		],
		fr: [
		  	"Dans AmberDay, chaque jour reçoit sa nuance unique en fonction de la qualité de cette journée",
		  	"Pour déterminer la couleur, vous comparez un nouveau jour avec les précédents",
		  	"Grâce à l'algorithme structuré, le nombre de comparaisons reste faible même lorsque les jours sont nombreux"
		],
		de: [
		  	"In AmberDay erhält jeder Tag seinen einzigartigen Farbton, abhängig davon, wie gut oder schlecht dieser Tag war",
		  	"Um die Farbe zu bestimmen, vergleichen Sie einen neuen Tag mit den vorherigen",
		  	"Dank des strukturierten Algorithmus bleibt die Anzahl der Vergleiche auch dann gering, wenn es viele Tage gibt"
		],
		it: [
		  	"In AmberDay, ogni giorno riceve la sua tonalità unica a seconda di quanto sia stato buono o cattivo quel giorno",
		  	"Per determinare il colore, si confronta un nuovo giorno con quelli precedenti",
		  	"Grazie all'algoritmo strutturato, il numero di confronti rimane ridotto anche quando ci sono molti giorni"
		],
		pt: [
		  	"No AmberDay, cada dia recebe sua tonalidade única dependendo de quão bom ou ruim aquele dia foi",
		  	"Para determinar a cor, você compara um novo dia com os anteriores",
		  	"Graças ao algoritmo estruturado, o número de comparações permanece pequeno mesmo quando há muitos dias"
		],
		fi: [
		  	"AmberDayssa jokainen päivä saa oman ainutlaatuisen sävynsä sen mukaan, kuinka hyvä tai huono päivä oli",
		  	"Värin määrittämiseksi vertaat uutta päivää edellisiin",
		  	"Rakenteellisen algoritmin ansiosta vertailujen määrä pysyy pienenä, vaikka päiviä olisi paljonkin"
		]
	},
	landingPaletteHead: {
		ru: "Выбери свой стиль",
		en: "Choose your own style",
		es: "Elige tu estilo",
		fr: "Choisissez votre style",
		de: "Wähle deinen Stil",
		it: "Scegli il tuo stile",
		pt: "Escolha seu estilo",
		fi: "Valitse tyylisi"
	},
	landingOfferPhrases: {
		ru: ["Запечатляй", "Запоминай", "Рефлексируй"],
		en: ["Remember", "Recall", "Reflect"],
		es: ["Captura", "Recuerda", "Reflexiona"],
		fr: ["Capture", "Mémorisez", "Reflexez"],
		de: ["Prägen", "Erinnern", "Reflektieren"],
		it: ["Cattura", "Ricorda", "Rifletti"],
		pt: ["Lembrar", "Relembrar", "Refletir"],
		fi: ["Kaappaa", "Muistaa", "Heijastaa"]
	}
}