
import { defineComponent, toHandlers } from "vue";
import Day from "@/components/Day.vue";
import {Day as DayClass} from "@/classes/Day";
import {Month as MonthClass} from "@/classes/Month";
import {MonthBuffer} from "@/classes/MonthBuffer";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { type Swiper as SwiperRef } from 'swiper';
// import { Virtual } from 'swiper/modules';
import "@/assets/general.scss";
import 'swiper/css';

export default defineComponent({
	components: { Swiper, SwiperSlide, Day },
	props: {
		// month: {type: MonthClass, required: true},
		month: {type: [MonthBuffer, MonthClass], required: true},
		single: Boolean,
		hideYear: Boolean,
		emptyDatesShown: Boolean,
		viewedDay: {type: DayClass, default: null},
	},
	data: () => {
		return {
			daysSwiper: null as SwiperRef|null,
			monthsSwiper: null as SwiperRef|null,
			pickMode: "days" as "days"|"months",
			monthsPickerYear: new Date().getFullYear() as number,
			monthBuffer: new MonthBuffer([new MonthClass(new Date())]) as MonthBuffer,

			numOfMountedDays: 0,
			// Virtual
		}
	},
	async beforeMount() {
		if (this.month instanceof MonthClass) {
			this.monthBuffer = new MonthBuffer([this.month], 0);
			await this.monthBuffer.loadNewIfNeeded();
		}
		else {
			this.monthBuffer = this.month;
		}
	},
	watch: {
		pickMode(newMode, oldMode) {
			this.$emit("pickModeChange", newMode);
			let yearEl = this.$el.getElementsByClassName("year")[0];
			yearEl.classList.add("year_anim");
			setTimeout(() => yearEl.classList.remove("year_anim"), 300)
		}
	},
	computed: {
		classes() {
			let arr = [];
			if (this.pickMode == 'months') arr.push('month_picked');
			if (this.single) arr.push('single');
			if (this.hideYear) arr.push('year_hidden');
			return arr;
		}
	},
	methods: {
		async fetchDaysSwiperObject(obj:SwiperRef) {
			// if (this.daysSwiper) return;
			this.daysSwiper = obj;
			await this.monthBuffer.assignSwiper(this.daysSwiper);
			this.daysSwiper.slideTo(this.monthBuffer.initialSlide !== null ? this.monthBuffer.initialSlide : this.monthBuffer.loadingRange, 0, false);
			this.$emit("swiperLoaded");
			// console.log(this.daysSwiper);
		},
		fetchMonthsSwiperObject(obj:SwiperRef) {
			this.monthsSwiper = obj;
		},
		isDayPicked(day:DayClass) {
			let vd = this.viewedDay;
			return vd != null && vd.toString("iso") == day.toString("iso");
		},
		async arrowClick(shift:-1|1) {
			if (this.pickMode == "days") {
				this.$emit("monthChange", this.monthBuffer.months[this.daysSwiper.activeIndex+shift]);
				this.daysSwiper.slideTo(this.daysSwiper.activeIndex+shift, 0);
			}
			else {
				this.monthsPickerYear += shift;
			}
		},
		async daysSwiperTransitionEnd(swiper:SwiperRef) {
			if (swiper.activeIndex != swiper.previousIndex && this.monthBuffer.months.length!=0) {
				this.$emit('slideTransitionEnd');
				this.monthBuffer.loadNewIfNeeded();
			}
		},
		daysSwiperTransitionStart() {
			this.$emit('slideTransitionStart');
		},
		async goToToday() {
			this.monthBuffer.slideToDate(new Date())
		},
		monthNameClick() {
			if (this.single) return;
			if (this.pickMode == 'days') this.monthsPickerYear = this.monthBuffer.shownMonth()!.date.getFullYear()!;
			this.pickMode = (this.pickMode == 'days') ? 'months' : 'days'
		},
		async monthPicked(i:number) {
			await this.monthBuffer.slideToDate(new Date(this.monthsPickerYear, i, 1));
			this.pickMode = 'days';
		},

		dayMounted() {
			this.numOfMountedDays++;
			if (this.numOfMountedDays == this.$el.getElementsByClassName("day").length)
				this.$emit("days-mounted");
		}
	},
	directives: {
		"day": {
			mounted(el, binding:any) {
				binding.instance.dayMounted();
			}
		}
	}
});
