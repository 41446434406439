import SecureLS from 'secure-ls'
var CryptoJS = require("crypto-js")

export default class Security {
	static keyFromPassword(password:string, salt:string) : string {
		return CryptoJS.PBKDF2(password, salt, {keySize: 256/32, iterations:1024}).toString()
	}

	static passwordHash(password:string, salt:string) : string {
		return CryptoJS.SHA256(password+salt).toString()
	}

	/* static getTrigrams(text:string, salt:string="", excludeSingleLettersForLongText=false):Array<string> {
		let trigrams = new Set<string>();
		let ls = new SecureLS();
		let trigLength = 8,
			offsetsPossible = 64/trigLength;
		text = text.replace(/[\.,\/#!\$%\^&\*;:{}=\-\\_`~()]/g,"");  //  \w не работает на кириллице

		const shuffleArray = (array:Array<any>) => {
			for (let i = array.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				const temp = array[i];
				array[i] = array[j];
				array[j] = temp;
			}
		}
		const hashTrigram = (trigram:string) => {
			trigram = CryptoJS.HmacSHA256(trigram.toLowerCase(), ls.get("encryptionKey")).toString();
			if (salt == "") return trigram;
			return CryptoJS.SHA256(trigram+salt).toString().substring(0,trigLength);
		}

		for (let i=0; i<text.length; i++) {
			if (i<text.length-2) {
				let substring = text.substring(i, i+3);
				if (!substring.match(" "))
					trigrams.add(hashTrigram(substring));
			}
			if (text[i] != " " && !(excludeSingleLettersForLongText && text.length >= 3)) trigrams.add(hashTrigram(text[i]));
		}
		let trigramsArr = Array.from(trigrams);
		shuffleArray(trigramsArr);
		return trigramsArr;

	} */
}