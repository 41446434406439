
import { defineComponent } from "vue";
import store from '@/store';
import router from '@/router';
import "@/assets/general.scss";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { type Swiper as SwiperRef } from 'swiper';
import 'swiper/css';

export default defineComponent({
	components: { Swiper, SwiperSlide },
	data: ()=> {
		return {
			swiper: null as SwiperRef|null,
			// initialPath: "" as string,
		}
	},
	mounted() {
		let fullPath = router.currentRoute.value.fullPath;
		if (!fullPath.match(/\/img\/?/)) {
			router.push(fullPath.replace(/\/$/, "")+"/img");
		}
		
	},
	unmounted() {
	},
	computed: {
		
	},
	methods: {
		fetchSwiperObject(obj:SwiperRef) {
			this.swiper = obj;
		},
		close() {
			store.commit("hideFullscreenImages");
			router.replace(router.currentRoute.value.fullPath.replace(/img\/?/, ""));
		},
		addCloseBySwipe() {
			let initialTouch:Touch,
				lastTouch:Touch,
				verticalShift:Boolean = true,
				verticalShiftChecked:Boolean = false;
			this.$el.addEventListener("touchstart", (e:TouchEvent) => {
				initialTouch = e.touches[0];
				lastTouch = e.touches[0];
				verticalShiftChecked = false;
			});
			this.$el.addEventListener("touchmove", (e:TouchEvent) => {
				e.preventDefault();
				e.stopPropagation();
				let yShift = e.touches[0].clientY - initialTouch.clientY,
					xShift = e.touches[0].clientX - initialTouch.clientX,
					treshold = 10;
				if (Math.abs(yShift) < treshold && !verticalShiftChecked) {
					if (Math.abs(yShift) < Math.abs(xShift)) verticalShift = false;
				}
				else if (verticalShift) {
					this.$el.classList.add("y_shifted");
					this.$el.style.setProperty("--y-shift-fraction", yShift/window.innerHeight);
					this.$el.style.setProperty("--x-shift-fraction", xShift/window.innerWidth);
					verticalShiftChecked = true;
				}
				lastTouch = e.touches[0];
			}, {passive: false});
			this.$el.addEventListener("touchend", (e:TouchEvent) => {
				let yShift = lastTouch.clientY - initialTouch.clientY;
				if (verticalShift) {
					let hide = Math.abs(yShift) > 60,
						newShift = hide ? Math.sign(yShift)*0.5 : 0,
						trs = 0.1;
					this.$el.style.setProperty("transition", `${trs}s`);
					this.$el.getElementsByTagName("img")[this.swiper.activeIndex].style.setProperty("transition", `${trs}s`);
					this.$el.style.setProperty("--y-shift-fraction", newShift);
					setTimeout(() => {
						this.$el.classList.remove("y_shifted");
						this.$el.style.removeProperty("transition");
						this.$el.getElementsByTagName("img")[this.swiper.activeIndex].style.removeProperty("transition");
						if (hide) this.close();
					}, trs*1000);
				}
				verticalShift = true;
				verticalShiftChecked = false;
			});
		},
	},
	directives: {
		"img": {
			mounted(el:any) {
				let adapt = () => {
					let fraction = window.innerWidth > 480 ? 75 : 100;
					if (window.innerWidth/window.innerHeight < el.offsetWidth/el.offsetHeight) {
						el.style.width = fraction+"vw";
						el.style.height = "auto";
					}
					else {
						el.style.height = fraction+"vh";
						el.style.width = "auto";
					}
				}
				adapt();
				window.addEventListener("resize", adapt);
			},
		},
		"fullscreen": {
			mounted(el, binding:any) {
				binding.instance.addCloseBySwipe();
			}
		}
	}
})
