import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-285515c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "theme_icon" }
const _hoisted_2 = {
  key: 0,
  class: "theme_icon_light",
  viewBox: "-1 -1 103 103"
}
const _hoisted_3 = {
  class: "sun_circle",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  fill: "none"
}
const _hoisted_4 = ["dur"]
const _hoisted_5 = ["dur"]
const _hoisted_6 = ["dur"]
const _hoisted_7 = ["dur"]
const _hoisted_8 = {
  class: "sun_beam",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  fill: "none"
}
const _hoisted_9 = ["dur", "from", "to"]
const _hoisted_10 = ["dur"]
const _hoisted_11 = {
  key: 1,
  class: "theme_icon_dark",
  viewBox: "-1 -1 103 103"
}
const _hoisted_12 = {
  key: 0,
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  fill: "none"
}
const _hoisted_13 = ["dur"]
const _hoisted_14 = ["dur"]
const _hoisted_15 = ["dur"]
const _hoisted_16 = ["dur"]
const _hoisted_17 = {
  class: "sun_beam",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  fill: "none"
}
const _hoisted_18 = ["id", "dur", "from", "to"]
const _hoisted_19 = ["dur"]
const _hoisted_20 = ["dur"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$store.state.theme == 'light')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_2, [
          _createElementVNode("path", _hoisted_3, [
            _createElementVNode("animate", {
              attributeName: "d",
              dur: _ctx.anDur+'s',
              repeatCount: "1",
              fill: "freeze",
              calcMode: "paced",
              keyTimes: "0; 0.85; 0.94; 0.95; 0.98; 1",
              keySplines: "0 0 1 1; 0 0 1 1; 0 0 1 1; 0 0 1 1; 0 0 1 1",
              values: "\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 40 40 0 0 0 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 100 100 0 0 0 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 1000 1000 0 0 0 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 1000 1000 0 0 1 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 70 70 0 0 1 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 50 50 0 0 1 63 2\r\n\t\t\t\t\t"
            }, null, 8, _hoisted_4),
            _createElementVNode("animateTransform", {
              attributeName: "transform",
              type: "translate",
              from: "5 5",
              to: "25 25",
              dur: _ctx.anDur+'s',
              repeatCount: "1",
              fill: "freeze",
              additive: "sum"
            }, null, 8, _hoisted_5),
            _createElementVNode("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: ".9",
              to: ".5",
              dur: _ctx.anDur+'s',
              repeatCount: "1",
              fill: "freeze",
              additive: "sum"
            }, null, 8, _hoisted_6),
            _createElementVNode("animateTransform", {
              attributeName: "transform",
              type: "rotate",
              from: "0 50 50",
              to: "240 50 50",
              dur: _ctx.anDur+'s',
              repeatCount: "1",
              fill: "freeze",
              additive: "sum"
            }, null, 8, _hoisted_7)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sunBeams, (beam) => {
            return (_openBlock(), _createElementBlock("path", _hoisted_8, [
              _createElementVNode("animate", {
                attributeName: "d",
                dur: _ctx.anDur+'s',
                repeatCount: "1",
                fill: "freeze",
                from: beam[0],
                to: beam[1]
              }, null, 8, _hoisted_9),
              _createElementVNode("animateTransform", {
                attributeName: "transform",
                type: "rotate",
                to: "0 50 50",
                from: "90 50 50",
                dur: _ctx.anDur+'s',
                repeatCount: "1",
                fill: "freeze",
                additive: "sum"
              }, null, 8, _hoisted_10)
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    (_ctx.$store.state.theme == 'dark')
      ? (_openBlock(), _createElementBlock("svg", _hoisted_11, [
          (_ctx.$store.state.theme == 'dark')
            ? (_openBlock(), _createElementBlock("path", _hoisted_12, [
                _createElementVNode("animate", {
                  attributeName: "d",
                  dur: _ctx.anDur+'s',
                  repeatCount: "1",
                  fill: "freeze",
                  calcMode: "paced",
                  keyTimes: "0; 0.02; 0.05; 0.06; 0.1; 1",
                  values: "\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 50 50 0 0 1 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 70 70 0 0 1 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 1000 1000 0 0 1 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 1000 1000 0 0 0 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 60 60 0 0 0 63 2;\r\n\t\t\t\t\t\tM 63 2 A 50 50 0 1 1 2 63 A 40 40 0 0 0 63 2;\r\n\t\t\t\t\t"
                }, null, 8, _hoisted_13),
                _createElementVNode("animateTransform", {
                  attributeName: "transform",
                  type: "translate",
                  from: "25 25",
                  to: "5 5",
                  dur: _ctx.anDur+'s',
                  repeatCount: "1",
                  fill: "freeze",
                  additive: "sum"
                }, null, 8, _hoisted_14),
                _createElementVNode("animateTransform", {
                  attributeName: "transform",
                  type: "scale",
                  from: ".5",
                  to: ".9",
                  dur: _ctx.anDur+'s',
                  repeatCount: "1",
                  fill: "freeze",
                  additive: "sum"
                }, null, 8, _hoisted_15),
                _createElementVNode("animateTransform", {
                  attributeName: "transform",
                  type: "rotate",
                  from: "-180 50 50",
                  to: "0 50 50",
                  dur: _ctx.anDur+'s',
                  repeatCount: "1",
                  fill: "freeze",
                  additive: "sum"
                }, null, 8, _hoisted_16)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sunBeams, (beam, i) => {
            return (_openBlock(), _createElementBlock("path", _hoisted_17, [
              _createElementVNode("animate", {
                id: 'dark_beam'+i,
                attributeName: "d",
                dur: _ctx.anDur+'s',
                repeatCount: "1",
                fill: "freeze",
                from: beam[1],
                to: beam[0]
              }, null, 8, _hoisted_18),
              _createElementVNode("animate", {
                attributeName: "stroke",
                dur: _ctx.anDur+'s',
                repeatCount: "1",
                fill: "freeze",
                from: "#fff",
                to: "#fff0"
              }, null, 8, _hoisted_19),
              _createElementVNode("animateTransform", {
                attributeName: "transform",
                type: "rotate",
                to: "-90 50 50",
                from: "0 50 50",
                dur: _ctx.anDur+'s',
                repeatCount: "1",
                fill: "freeze",
                additive: "sum"
              }, null, 8, _hoisted_20)
            ]))
          }), 256))
        ]))
      : _createCommentVNode("", true),
    (_ctx.splashIndex>0)
      ? (_openBlock(), _createElementBlock("div", {
          class: "splash",
          key: _ctx.splashIndex,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.splashClick && _ctx.splashClick(...args)))
        }))
      : _createCommentVNode("", true)
  ]))
}