import {Day as Day, IDay} from "./Day";
import {Month as Month, IMonth} from "./Month";
import { type Swiper as SwiperRef } from 'swiper';

export class MonthBuffer {
	private _months:Array<Month|null> = [];
	private _loadingRange:number;
	private static _loadingRangeDefault:number = 2;
	private _swiper:SwiperRef;
	private tasksToPerformWhenSwiperLoaded = [] as Array<(swiper:SwiperRef) => void>;
	private _initialSlide:number|null = 0;

	get months() { return this._months}
	get loadingRange() { return this._loadingRange}
	get swiper() { return this._swiper}
	get initialSlide() { return this._initialSlide }
	static get loadingRangeDefault() { return MonthBuffer._loadingRangeDefault }

	constructor(months:Array<Month|null>, loadingRange:number = MonthBuffer._loadingRangeDefault, initialSlide:number|null = null) {
		this._months = months;
		this._loadingRange = loadingRange;
		this._initialSlide = initialSlide;
	}

	static async create(monthDate:Date, loadingRange:number = MonthBuffer._loadingRangeDefault, initialSlide:number|null = null):Promise<MonthBuffer> {
		monthDate = Month.fixDate(monthDate);

		let arrayWithRangeFromCenter = (center:number, range:number) => {
			const arr = [];
			for (let i = center-range; i <= center+range; i++) arr.push(i);
			return arr;
		}
		let shiftsArr = arrayWithRangeFromCenter(0, loadingRange);

		let promisesArr = [] as Array<Promise<Month|null>>;
		for (let i in shiftsArr) {
			let monthDateCopy = new Date(monthDate);
			monthDateCopy.setMonth(monthDateCopy.getMonth()+shiftsArr[i])
			promisesArr.push(Month.load(monthDateCopy));
		}

		return Promise.all(promisesArr).then((resArr) =>{
			return new MonthBuffer(resArr, loadingRange, initialSlide);
		});
	}
	static empty():MonthBuffer {
		return new MonthBuffer([], 0);
	}
	isEmpty():Boolean {
		return this.months.length==0;
	}

	performWhenSwiperLoaded(fn: (swiper:SwiperRef) => void) {
		if (this.swiper) fn(this.swiper);
		else this.tasksToPerformWhenSwiperLoaded?.push(fn);
	}
	async assignSwiper(swiper:SwiperRef) {
		this._swiper = swiper;
		for (let task of this.tasksToPerformWhenSwiperLoaded) {
			task(this._swiper);
		}
		this.tasksToPerformWhenSwiperLoaded = [];
		// this.loadNewIfNeeded();
	}

	shownMonth():Month|null {
		return this.months[this.swiper.activeIndex] ?? this.months[0];
	}

	async loadNewIfNeeded() {
		if (!this.swiper) return;
		if (this.swiper.activeIndex < 0 || this.swiper.activeIndex >= this.months.length)
			throw new RangeError("Out of range of current months array");

		let monthsToLoad = 0,
			toSide: -1|1;
		if (this.swiper.activeIndex >= this.months.length-this.loadingRange) {
			monthsToLoad = this.swiper.activeIndex - (this.months.length-1) + this.loadingRange;
			toSide = 1;
		}
		else if (this.swiper.activeIndex <= this.loadingRange-1) {
			monthsToLoad = this.loadingRange - this.swiper.activeIndex;
			toSide = -1;
		}
		else return;
		
		this.swiper.allowTouchMove = false;
		let promisesArr = [] as Array<Promise<Month|null>>;
		let monthsToUnshift = [] as Array<Month>;
		for (let i=0; i < monthsToLoad; i++) {
			let dateToLoad = this.months[toSide == -1 ? 0 : this.months.length-1]!.getDateOfMonthWithShift(toSide);
			if (toSide==1) this.months.push(new Month(dateToLoad));
			else {
				this.months.unshift(new Month(dateToLoad));
				this.swiper.slideNext(0, false);
			}
			promisesArr.push(Month.load(dateToLoad));
		}
		this.swiper.allowTouchMove = true;

		Promise.all(promisesArr).then((resArr) =>{
			for (let i in resArr) {
				if (resArr[i]) 
					this._months[this._months.findIndex((m) => m?.toString() == resArr[i]?.toString())]!.days = resArr[i]!.days;
			}
		});
	}

	async slideToDate(date:Date, emitEvent:Boolean = true) {
		let index = this.months.findIndex(m => m?.toString() == new Month(date).toString());
		if (index != -1) {
			this.swiper.slideTo(index, 0, emitEvent);
			await this.loadNewIfNeeded();
			return;
		}
		// решил не париться
		let newBuffer = await MonthBuffer.create(date, this._loadingRange);
		this._months = newBuffer.months;
		this.swiper.slideTo(this._loadingRange, 0, emitEvent);
	}

	async reloadAllMonths() {
		let promisesArr = [] as Array<Promise<Month|null>>;
		for (let i in this.months) {
			promisesArr.push(Month.load(this.months[i]!.date));
		}
		return Promise.all(promisesArr).then((resArr) =>{
			for (let i in this.months) {
				this.months[i]!.days = resArr[i]!.days;
			}
		});
	}
}