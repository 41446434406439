
import { defineComponent } from 'vue';
import router from '@/router';
import store from '@/store';
import Month from "@/components/Month.vue";
import DayNote from "@/components/DayNote.vue";
import {Day as DayClass} from "../classes/Day";
import {Month as MonthClass} from "../classes/Month";
import { MonthBuffer } from '@/classes/MonthBuffer';

export default defineComponent({
	props: {
		month: {
			required: true,
			type: [MonthClass, MonthBuffer]
		},
		initialViewedDay: {
			type: DayClass
		},
		singleMonth: Boolean,
		monthHideYear: Boolean,
		dayNoteToggles: { type: Boolean, default: true },
		disableDayNoteMobileSetting: { type: Boolean, default: false },
		disableDayNoteSwipeLine: { type: Boolean, default: false },
	},
	data: () => {
		return {
			dayNoteClasses: [] as String[],
			prevViewedDayHadImg: null as Boolean|null,  // note: it's null if no day is viewed
			viewedDay: null as DayClass|null,
			monthBuffer: MonthBuffer.empty() as MonthBuffer,
			monthPickMode: "days" as "days"|"months",
			initialMinHeight: 0 as number,
			monthAsideScale: 0,
		}
	},
	components: { Month, DayNote },
	computed: {
		monthWrapperClasses() {
			var arr=[];
			if (this.viewedDay != null) {
				arr.push('month_aside');
			}
			return arr;
		},
	},
	async beforeMount() {
		if (this.month instanceof MonthClass) {
			this.monthBuffer = new MonthBuffer([this.month], 0);
			await this.monthBuffer.loadNewIfNeeded();  
		}
		else {
			this.monthBuffer = this.month;
		}
	},
	async mounted() {
		window.addEventListener("scroll", this.setMonthScrollPosition, {
			capture: true,
			passive: true
		} as EventListenerOptions);
	},
	methods: {
		swiperLoaded() {
			if (this.initialViewedDay) this.openDayNote(this.monthBuffer.shownMonth()!.days[this.initialViewedDay.date.getDate()-1]);
		},
		openDayNote(day: DayClass) {
			// проверка нужна для того, чтобы не анимировалось заново после удаления и повторного нажатия на день в календаре. мелочь, а бесит
			if (!this.viewedDay || this.viewedDay.date != day.date) {
				if (this.viewedDay) this.prevViewedDayHadImg = this.viewedDay.img.length != 0;
				this.viewedDay = day.clone();
				this.$emit("dayNoteOpened", day.clone());
			} 
		}, 
		slideTransitionStart() {
			/* if (this.monthBuffer.swiper && this.viewedDay != null && window.innerWidth <= 480) {
				let dayNoteEl = document.getElementsByClassName("day_note")[0] as HTMLElement;
				this.adaptNoteHeight(dayNoteEl);
				this.setMobileNoteExpansion(dayNoteEl);
				dayNoteEl.style.setProperty("--initial-height", dayNoteEl.offsetHeight+"px");
			} */
		},
		slideTransitionEnd() {
			if (this.monthBuffer.swiper && this.viewedDay != null && window.innerWidth <= 480) {
				this.setMobileNoteExpansion();
			}
		},
		monthPickModeChange(mode:"days"|"months") {
			this.monthPickMode = mode;
			if (window.innerWidth <= 480) {
				this.setMobileNoteExpansion();
			}
		},
		closeDayNote(animated=true) {  // да, я знаю про вьюшные transition. здесь они, почему-то работают не так плавно, что странно.
			var monthEl = document.getElementById("shown_month_wrapper")!;
			this.dayNoteClasses.push("closing");
			setTimeout(() => {
				this.prevViewedDayHadImg = null;
				this.viewedDay = null;
				this.dayNoteClasses.splice(this.dayNoteClasses.indexOf("closing"), 1);
			}, animated ? 200 : 1);
			this.$emit("dayNoteClosed");
		},
		setMonthAsideScale() {
			const monthEl = document.getElementById("shown_month_wrapper")! as HTMLElement;
			this.monthAsideScale = this.$el.offsetWidth * 0.31 / monthEl.offsetWidth;
		},
		setMonthScrollPosition() {
			if (this.viewedDay == null) return;

			var monthElem = document.getElementById("shown_month_wrapper")!;
			var noteElem = document.getElementById("day_note")!;
			if (monthElem.getBoundingClientRect().bottom < 0) return;

			if (monthElem.offsetHeight*1.2 < noteElem.offsetHeight && noteElem.getBoundingClientRect().top < 0) {
				if (noteElem.getBoundingClientRect().bottom >= (monthElem.offsetHeight * this.monthAsideScale)+40) {
					monthElem.style.setProperty("--scroll-shift", "0px");
					monthElem.classList.add("month_fixed");
				}
				else {
					monthElem.style.setProperty("--scroll-shift", noteElem.offsetTop+noteElem.offsetHeight-(monthElem.offsetHeight * this.monthAsideScale)-40+"px");
					monthElem.classList.remove("month_fixed");
				}
			}
			else {
				monthElem.style.setProperty("--scroll-shift", "0px");
				monthElem.classList.remove("month_fixed");
			}
		},
		async reloadData() {
			await this.monthBuffer.reloadAllMonths();
			this.viewedDay = DayClass.Empty(this.viewedDay!.date);
		},
		setMobileNoteExpansion() {
			if (window.innerWidth > 480 || this.disableDayNoteMobileSetting) return;
			let el = document.getElementsByClassName("day_note")[0] as HTMLElement;
			let monthEl = document.getElementById("shown_month_wrapper")!;
			el.style.setProperty("--initial-position", monthEl.offsetHeight+monthEl.offsetTop+20+"px");
			el.style.height = (document.documentElement.clientHeight - el.getBoundingClientRect().top)+"px";
			el.style.setProperty("--initial-height", el.offsetHeight+"px");
			if (window.innerWidth <= 480) {
				let mousedown = false,
					dragged = false,
					initialY = 0,
					initialHeight = 0,
					lastTouch:Touch,
					isInUpperPosition = false,
					upperPositionShift = 0,
					innerElem = el.getElementsByClassName("day_note_inner")[0],
					imgElem = el.getElementsByTagName("img")[0],
					imgWrapperElem = el.getElementsByClassName("img_wrapper")[0] as HTMLElement;

				el.addEventListener("touchstart", (e:TouchEvent)=> {
					if (store.state.fullscreenImgArr != null && store.state.fullscreenImgArr.length != 0) return;
					mousedown = true;
					if (!isInUpperPosition) initialHeight = el.offsetHeight;
					initialY = e.touches[0].clientY;
					upperPositionShift = 0 - window.innerHeight + initialHeight;
					el.classList.add("y_shifted");
					el.style.setProperty("--max-shift-up", window.innerHeight-initialHeight+"px");
				});

				window.addEventListener("touchmove", (e:TouchEvent)=> {
					if (store.state.fullscreenImgArr != null && store.state.fullscreenImgArr.length != 0) return;
					if (mousedown) {
						dragged = true;
						// preventing refreshing on pull-down
						if ((innerElem.scrollTop == 0 || !isInUpperPosition) && (!lastTouch || (lastTouch.clientY-e.touches[0].clientY)<0)){
							e.preventDefault();
							e.stopPropagation();
						}
						let shift = e.touches[0].clientY-initialY;
						if (isInUpperPosition) {
							if (innerElem.scrollTop > 0) {
								dragged = false;
								initialY = e.touches[0].clientY;
								return;
							}
							shift += upperPositionShift;
						}
						shift = shift < upperPositionShift ? upperPositionShift : shift;  // not letting to shift above the top of the screen
						el.style.setProperty("--y-shift", shift + "px");
						el.style.setProperty("--y-shift-up-fraction", `${shift/(initialHeight-window.innerHeight)}`);
						if (shift == upperPositionShift) el.classList.add("upper_position");
						else el.classList.remove("upper_position");
						if (e.touches[0].clientY < initialY) { // up
							el.classList.add("y_shifted_up");
							// не по-христиански конечно так делать, но чёт слишком заморочисто будет это декларативно задавать
							if (!isInUpperPosition) el.querySelector(".swipe_line path")!.setAttribute("d", "M 2 3 L 10 1 L 18 3");
						}
						else {
							if (!isInUpperPosition) el.classList.remove("y_shifted_up");
							el.querySelector(".swipe_line path")!.setAttribute("d", "M 2 3 L 10 3 L 18 3");
						}
						if (e.touches[0].clientY > initialY) { // down
							el.querySelector(".swipe_line path")!.setAttribute("d", "M 2 3 L 10 5 L 18 3");
						}
						lastTouch = e.touches[0];
					}
				}, {passive: false});

				window.addEventListener("touchend", (e:TouchEvent)=> {
					el.querySelector(".swipe_line path")!.setAttribute("d", "M 2 3 L 10 3 L 18 3");
					if (store.state.fullscreenImgArr != null && store.state.fullscreenImgArr.length != 0) return;
					if (mousedown && dragged) {
						let trs = 0.2,
							del = false,
							staticOrfromUpper = false;
						el.style.setProperty("transition", `${trs}s`);
						if (!!imgElem) imgElem.style.setProperty("transition", `${trs}s`);
						if (!!imgWrapperElem) imgWrapperElem.style.setProperty("transition", `${trs}s`);
						setTimeout(() => {
							el.style.removeProperty("transition");
							if (!!imgElem) imgElem.style.removeProperty("transition");
							if (!!imgWrapperElem) imgWrapperElem.style.removeProperty("transition");
							if (!isInUpperPosition) el.classList.remove("y_shifted_up");
							if (staticOrfromUpper) el.classList.remove("y_shifted");
							if (del) this.closeDayNote(false);
						}, trs*1000);
						if (initialY - lastTouch.clientY > 80) { // up
							el.classList.add("upper_position");
							el.style.setProperty("--y-shift", (0 - window.innerHeight + initialHeight) + "px");
							el.style.setProperty("--y-shift-up-fraction", "1");
							isInUpperPosition=true;
						}
						else if ((initialY - lastTouch.clientY < -80) && !isInUpperPosition) { // down from initial position
							el.style.setProperty("--y-shift", initialHeight+"px");
							el.classList.remove("upper_position");
							del = true;
							isInUpperPosition=false;
						}
						else {  // static or down from upper position
							initialY = 0;
							initialHeight = 0;
							el.style.setProperty("--y-shift", "0px");
							el.style.setProperty("--y-shift-up-fraction", "0");
							el.classList.remove("upper_position");
							staticOrfromUpper=true;
							isInUpperPosition=false;
						}
						mousedown = false;
						dragged = false;
					}
				});
			}
		},
		dayNoteKey(day:DayClass) {
			return window.innerWidth <= 480 ? day.toString('iso') : '0';
		},
	},
	beforeUnmount() {
		window.removeEventListener("scroll", this.setMonthScrollPosition, {
			capture: true,
			passive: true
		} as EventListenerOptions);
		window.removeEventListener("resize", this.setMonthAsideScale, {
			capture: true,
			passive: true
		} as EventListenerOptions);
	},
	directives: {
		"day-note": {
			mounted(el:HTMLElement, binding:any) {
				binding.instance.setMobileNoteExpansion();
				// binding.instance.$el.style.height = null;
				binding.instance.$el.style.height = el.offsetHeight+"px";
				window.addEventListener("resize", () => {
					binding.instance.setMobileNoteExpansion();
					// binding.instance.$el.style.height = null;
					binding.instance.$el.style.height = el.offsetHeight+"px"
				});
				document.getElementById("shown_month_wrapper")!.style.setProperty("--scroll-shift", "0px");
				binding.instance.setMonthScrollPosition();
			},
			updated(el:HTMLElement, binding:any) {
				if (window.innerWidth > 480) {
					binding.instance.$el.style.height = null;
					binding.instance.$el.style.height = el.offsetHeight+"px"
				}
				document.getElementById("shown_month_wrapper")!.style.setProperty("--scroll-shift", "0px");
				binding.instance.setMonthScrollPosition();
			},
			beforeUnmount(el:HTMLElement, binding:any) {
				// binding.instance.$el.style.height = binding.instance.initialMinHeight;
				binding.instance.$el.style.height = null;
				binding.instance.$el.style.height = document.getElementById("shown_month_wrapper")!.offsetHeight+"px"
			},
		},
		"month": {
			mounted: (el, binding:any) => {
				binding.instance.initialMinHeight = el.offsetHeight;
				binding.instance.setMonthAsideScale();
				window.addEventListener("resize", binding.instance.setMonthAsideScale, {
					capture: true,
					passive: true
				} as EventListenerOptions);
			}
		}
	}
})
