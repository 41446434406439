import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ab69d92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "month_viewer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Month = _resolveComponent("Month")!
  const _component_DayNote = _resolveComponent("DayNote")!
  const _directive_month = _resolveDirective("month")!
  const _directive_day_note = _resolveDirective("day-note")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "shown_month_wrapper",
      class: _normalizeClass(["month_wrapper", _ctx.monthWrapperClasses]),
      style: _normalizeStyle(`--month-aside-scale: ${_ctx.monthAsideScale}`)
    }, [
      (_ctx.monthBuffer != null && _ctx.monthBuffer.months.length!=0)
        ? _withDirectives((_openBlock(), _createBlock(_component_Month, {
            key: 0,
            month: (_ctx.monthBuffer as MonthBuffer),
            onDayClick: _ctx.openDayNote,
            "viewed-day": (_ctx.viewedDay as DayClass),
            id: "month",
            single: _ctx.singleMonth,
            "hide-year": _ctx.monthHideYear,
            onSlideTransitionStart: _ctx.slideTransitionStart,
            onSlideTransitionEnd: _ctx.slideTransitionEnd,
            onSwiperLoaded: _ctx.swiperLoaded,
            onPickModeChange: _ctx.monthPickModeChange,
            onDaysMounted: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('days-mounted')))
          }, null, 8, ["month", "onDayClick", "viewed-day", "single", "hide-year", "onSlideTransitionStart", "onSlideTransitionEnd", "onSwiperLoaded", "onPickModeChange"])), [
            [_directive_month]
          ])
        : _createCommentVNode("", true)
    ], 6),
    (_ctx.viewedDay != null)
      ? _withDirectives((_openBlock(), _createBlock(_component_DayNote, {
          id: "day_note",
          class: _normalizeClass(_ctx.dayNoteClasses),
          day: (_ctx.viewedDay as DayClass),
          onClose: _ctx.closeDayNote,
          onDayDelete: _ctx.reloadData,
          closable: "",
          "swipe-line": _ctx.$store.state.mobileWidth && !_ctx.disableDayNoteSwipeLine,
          key: _ctx.dayNoteKey(_ctx.viewedDay as DayClass),
          "toggles-list": ['edit','delete'],
          toggles: _ctx.dayNoteToggles
        }, null, 8, ["class", "day", "onClose", "onDayDelete", "swipe-line", "toggles"])), [
          [_directive_day_note]
        ])
      : _createCommentVNode("", true)
  ]))
}