
import { defineComponent } from 'vue';
import router from '@/router';
import store from '@/store';
import Day from "@/components/Day.vue";
import {Day as DayClass} from "../classes/Day";
import { ColorPalette, StandardColorPalettes, IRGBColor } from "@/classes/ColorPalette"
import { ColorPicker } from 'vue-accessible-color-picker'

export default defineComponent({
	data: () => {
		return {
			colorPalettesList: StandardColorPalettes,
			openedTab: store.state.colorPalette.name == "Custom" ? "custom" : "standard" as "standard"|"custom",
			customTab: 'best' as "best"|"worst",
			customTabBestDay: new DayClass(new Date(), 1),
			customTabWorstDay: new DayClass(new Date(), 0.00001),
			colorPickerMouseDown: false as boolean,
		}
	},
	components: { Day, ColorPicker },
	props: {
		transparent: Boolean
	},
	computed: {
		customPaletteStartColor() {
			let c = this.customTab == 'worst' ? store.state.colorPalette.startColor : store.state.colorPalette.endColor;
			return `rgb(${c.r}, ${c.g}, ${c.b})`
		}
	},
	mounted() {
		window.onmouseup = () => {
			this.colorPickerMouseDown = false;
		}
	},
	watch: {
		colorPickerMouseDown(newVal) {
			let mainEl = document.getElementsByTagName("main")[0];
			if (!mainEl) return;
			if (newVal) mainEl.classList.add("palette_color_picked");
			else mainEl.classList.remove("palette_color_picked");
		}
	},
	methods: {
		switchColorPalette(cpName:any) {
			store.commit("setColorPalette", StandardColorPalettes[cpName]);
		},
		switchOpenedTab(mode:"standard"|"custom") {
			this.openedTab = mode;
			if (mode == "custom") {
				if (!store.state.customColorPalette) {
					let cp = store.state.colorPalette;
					store.state.customColorPalette = new ColorPalette(cp.startColor, cp.endColor, "Custom");
				}
				store.state.colorPalette = store.state.customColorPalette;
			}
		},
		customPaletteColorChange(newColor:any) {
			let color = { r: 255*newColor.colors.rgb.r, g: 255*newColor.colors.rgb.g, b: 255*newColor.colors.rgb.b }
			if (this.customTab == "worst") {
				store.state.customColorPalette!.startColor = color;
				store.commit("setColorPalette", new ColorPalette(color, store.state.colorPalette.endColor, "Custom"));
			}
			if (this.customTab == "best") {
				store.state.customColorPalette!.endColor = color;
				store.commit("setColorPalette", new ColorPalette(store.state.colorPalette.startColor, color, "Custom"));
			}
		}
	}
})
