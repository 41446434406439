
import { defineComponent } from "vue";
import {Day as DayClass} from "@/classes/Day";
import store from "@/store";

export default defineComponent({
	props: {
		day: DayClass,
		gap: Boolean,
		disabled: Boolean,
		interactive: Boolean,
		picked: Boolean,
		showDate: {type: Boolean, default: true},
		emptyDatesShown: Boolean,
		todayShown: Boolean
	},
	computed: {
		classesArr() : Array<string> {
			let arr = [];
			//console.log(this.$props);
			if (this.gap) arr.push("gap");
			if (this.disabled) arr.push("disabled");
			if (this.interactive) arr.push("interactive")
			if (this.picked) arr.push("picked")
			if (this.day?.rate === undefined) arr.push("empty");
			if (this.emptyDatesShown) arr.push("empty_dates_shown");
			if (this.todayShown && this.day !== undefined && DayClass.compare(this.day, new Date())) arr.push("today");
			if (store.state.colorPalette.crossEmptyDays) arr.push("empty_cross")
			return arr;
		},
	},
	methods: {
		getStyle() : string {
			return `background: ${this.day?.colorString}`;
		}
	}
})
