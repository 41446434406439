
import { defineComponent, toHandlers } from "vue"
import axios from 'axios'
import router from '@/router'
import store from "@/store"
import Security from '@/classes/Security'
import SecureLS from 'secure-ls'
import { PropType } from "vue";
var CryptoJS = require("crypto-js")

export enum LoginFrameMode { login = "login", signup = "signup" }

export default defineComponent({
	data: () => {
		return {
			mode: 0 as 0|1|2, // 0 - login, 1 - signup, 2 - secret question
			loginData: { email: "", password: "", crossDomain: store.state.appMode == "mobile", xhrFields: { withCredentials: true } },
			signupData: { email: "", password: "", reserveKey: "", crossDomain: store.state.appMode == "mobile", xhrFields: { withCredentials: true } },
			userExists: "unchecked" as Boolean|"unchecked",
			question: "default",
			error: false,
			modeWasChanged: false,
		}
	},
	props: {
		initialMode: {
			type: String as PropType<LoginFrameMode>,
			default: LoginFrameMode.login
		},
		autofocus: {
			type: Boolean,
			default: true
		}
	},
	mounted() {
		//if(this.$store.state.jwt != "") router.push();
		//setTimeout(() => { document.getElementsByTagName("input")[0].focus(); this.$forceUpdate(); }, 1000)
		this.mode = this.initialMode == LoginFrameMode.login ? 0 : 1;
	},
	computed: {
		disabled() {
			switch (this.mode) {
				case 0: return !this.loginDataValid();
				case 1: return !this.signupDataValid(false);
				case 2: return !this.signupDataValid();
			}
		},
		emailValid() {
			return this.signupData.email.toLowerCase().trim().match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)!==null;
		},
	},
	methods: {
		autofocusFirstInput(el:HTMLElement) {
			if (this.modeWasChanged || this.autofocus) el.focus();
		},
		loginDataValid():Boolean {
			return this.loginData.email.trim()!='' && this.loginData.password.trim()!=''
		},
		submitLogin() {
			this.error = false;

			axios.post(store.state.apiRoot+`api/auth/login.php`, this.loginData)
			.then(res => {
				console.log(res.data);
				if (!res.data.success) {
					this.error = true;
				}
				else {
					let ls = new SecureLS();
					let extractedPass = Security.keyFromPassword(this.loginData.password, res.data.salt);
					ls.set("encryptionKey", CryptoJS.AES.decrypt(res.data.encryptionKey, extractedPass).toString(CryptoJS.enc.Utf8));
					store.state.jwt = res.data.jwt;
					store.state.userName = res.data.userName;
					router.push("calendar");
				}
			});
		},
		
		signupDataValid(includeReserveKey:Boolean=true):Boolean {
			let emailArr = this.signupData.email.split('@').filter((val) => val.trim()!="");
			return this.signupData.email.trim()!='' &&
				this.signupData.password.trim()!='' &&
				this.userExists===false &&
				(!includeReserveKey || this.signupData.reserveKey.trim()!='') &&
				this.emailValid;
		},
		checkUserExists() {
			this.userExists = "unchecked";
			if (!this.emailValid) return;
			axios.get(store.state.apiRoot+"api/auth/checkUserExists.php", { params: {email: this.signupData.email} })
			.then(res=> {
				console.log(res.data);
				if (res.data.success) {
					this.userExists = res.data.exists;
				}
			})
		},
		continueSignup() {
			if (!this.signupDataValid(false)) return;
			this.mode=2;
		},
		questionChange() {
			document.getElementById("answer")?.focus();
		},
		submitSignup() {
			if (!this.signupDataValid()) return;
			this.error = false;

			let sentData = JSON.parse(JSON.stringify(this.signupData)) as Record<string,any>;
			sentData.salt = CryptoJS.lib.WordArray.random(256 / 8).toString();  // this will be used as a salt for both hashing and extracting to a key
			sentData.password = Security.passwordHash(this.signupData.password, sentData.salt);
			
			let rawKey = CryptoJS.lib.WordArray.random(256 / 8).toString();  // this will be used as a key of encryption of notes etc
			let extractedPass = Security.keyFromPassword(this.signupData.password, sentData.salt)  // this will be used to encrypt the encryption key
			let extractedSecret = Security.keyFromPassword(this.signupData.reserveKey.trim().toLowerCase(), sentData.salt)
			sentData.encryptionKey = CryptoJS.AES.encrypt(rawKey, extractedPass).toString();
			sentData.reserveKey = CryptoJS.AES.encrypt(rawKey, extractedSecret).toString();

			axios.post(store.state.apiRoot+`api/auth/signup.php`, sentData)
			.then(res => {
				console.log(res.data);
				if (!res.data.success) {
					this.error = true;
				}
				else {
					let ls = new SecureLS();
					ls.set("encryptionKey", rawKey);
					this.$store.state.jwt = res.data.jwt;
					router.push("calendar");
				}
			});
		},
		switchMode(mode:0|1|2) {
			//for (let key in this.signupData) this.signupData[key as keyof typeof this.signupData] = "";
			this.error=false;
			this.mode = mode;
			this.modeWasChanged = true;
		},
	},
	directives: {
		"first-input": {
			mounted(el, binding:any) {
				binding.instance.autofocusFirstInput(el);
			}
		}
	}
})
