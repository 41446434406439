import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "loading_icon_general_wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!
  const _component_ImgFullscreen = _resolveComponent("ImgFullscreen")!
  const _component_BottomNavBar = _resolveComponent("BottomNavBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.noHeaderPages.includes(_ctx.$route.name?.toString()!))
      ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$store.state.loading)
        ? (_openBlock(), _createBlock(_component_LoadingIcon, {
            key: 0,
            id: "loading_icon_general"
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$store.state.fullscreenImgArr != null && _ctx.$store.state.fullscreenImgArr.length != 0)
      ? (_openBlock(), _createBlock(_component_ImgFullscreen, { key: 1 }))
      : _createCommentVNode("", true),
    (!_ctx.noBottomNavBarPages.includes(_ctx.$route.name?.toString()!))
      ? (_openBlock(), _createBlock(_component_BottomNavBar, { key: 2 }))
      : _createCommentVNode("", true)
  ], 64))
}