
import { defineComponent } from "vue";
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import Day from "@/components/Day.vue";
import {Day as DayClass, IImageInfo} from "@/classes/Day";
import {Month as MonthClass} from "@/classes/Month";

export default defineComponent({
	components: { Day },
	props: {
		day: { type: DayClass, required: true },
		closable: { type: Boolean, default: false },
		comparedDay: { type: DayClass },
		toggles: { type: Boolean, default: true },
		togglesList: { type: Array, default: [] },
		hideImage: { type: Boolean, default: false },
		highlightMatches: { type: Array, default: [] },
		listItem: { type: Boolean, default: false },
		swipeLine: { type: Boolean, default: false },
		disableComparisonSwipe: { type: Boolean, default: false }
	},
	data() {
		return {
			deleteQuestionShown: false,
			fullscreenImgIndex: null as number|null,
			shownFull: true as Boolean,
		}
	},
	computed: {
		classes() {
			let arr = [];
			if (this.comparedDay !== undefined) arr.push("comparison");
			if (this.day.img && this.day.img.length!=0 && this.day.imgPaths[0].trim() != '') arr.push("contains_img");
			if (this.listItem) arr.push("list_item");
			if (!this.toggles || this.togglesList.length == 0) arr.push("no_toggles")
			return arr;
		},
		relativeDay() {
			const difference = new DayClass(new Date()).date.getTime() - this.day.date.getTime();
			if (difference == 0) return store.getters.caption("today");
			if (difference == 1000*3600*24) return store.getters.caption("yesterday");
			if (difference == -1000*3600*24) return store.getters.caption("tomorrow");
			return false;
		},
	},
	mounted() {
		let fullPath = router.currentRoute.value.fullPath
		if (fullPath.match(/\/img\/?/)) {
			let opened = this.openImageFullscreen();
			if (!opened) router.replace(fullPath.replace(/\/img\/?/, ""));
		}

		let getWordBoundings = (str:String, pos:number) : number[] => {
			var left = str.slice(0, pos + 1).search(/[^\s\.,\/#!$%\^&\*;:{}=\-\\_`~()<>]+$/),
				right = str.slice(pos).search(/[\s\.,\/#!$%\^&\*;:{}=\-\\_`~()<>]/);
			if (right < 0) {
				return [left, str.length];
			}
			return [left, right + pos];
		}
		
		if (this.day.note) {
			let shift = 0;
			for (let requiredMatchIndex in this.highlightMatches) {
				let index = (this.highlightMatches[requiredMatchIndex] as Array<number>)[0];
				let wordBoundings = getWordBoundings(this.day.note, index+shift);
				let spanOpening = "<span class='highlight'>",
					spanClosing = "</span>";
				let spanOpeningPos = wordBoundings[0] - spanOpening.length; // we need it if one exists already
				// avoiding double-highlighting or tags crossing
				if (spanOpeningPos < 0 || this.day.note.substring(spanOpeningPos, wordBoundings[0]) != spanOpening) {
					this.day.note = this.day.note.slice(0, wordBoundings[0]) + spanOpening + this.day.note.slice(wordBoundings[0]);
					wordBoundings[1] += spanOpening.length;
					this.day.note = this.day.note.slice(0, wordBoundings[1]) + spanClosing + this.day.note.slice(wordBoundings[1]);
					shift += spanOpening.length + spanClosing.length;
				}
			}
		}

		if (this.comparedDay !== undefined && !this.disableComparisonSwipe) {
			let initialTouch:Touch,
				lastTouch:Touch,
				horisontalShift:Boolean = true,
				horisontalShiftChecked:Boolean = false;
			this.$el.addEventListener("touchstart", (e:TouchEvent)=> {
				if (this.fullscreenImgIndex != null || (e.target! as any).className == "head_caption") return;
				initialTouch = e.touches[0];
				lastTouch = e.touches[0];
				horisontalShiftChecked = false;
				this.$el.classList.add("shifted");
			})
			window.addEventListener("touchmove", (e:TouchEvent)=> {
				if (!horisontalShift) return;
				let yShift = e.touches[0].clientY - initialTouch.clientY,
					xShift = e.touches[0].clientX - initialTouch.clientX,
					treshold = 10;
				if (Math.abs(xShift) < treshold && !horisontalShiftChecked) {
					if (Math.abs(yShift) > Math.abs(xShift)) { 
						horisontalShift = false;
						this.$el.classList.remove("shifted");
					}
				}
				else if (horisontalShift) {
					horisontalShiftChecked = true;
					this.$el.style.setProperty("--shift", e.touches[0].clientX-initialTouch.clientX);
				}
				lastTouch = e.touches[0];
			})
			window.addEventListener("touchend", (e:TouchEvent)=> {
				if (horisontalShift) {
					let trs = 0.2;
					this.$el.style.setProperty("transition", `${trs}s`);
					setTimeout(() => { this.$el.style.removeProperty("transition", "0") }, trs*1000);
					if (Math.abs(initialTouch.clientX-lastTouch.clientX) > 50) {
						this.$el.style.setProperty("transition", `${trs}s`);
						this.$el.style.setProperty("--shift", (lastTouch.clientX-initialTouch.clientX)*2);
						this.$emit("dayCompared", Math.sign(initialTouch.clientX-lastTouch.clientX));
					}
					else {
						this.$el.classList.remove("shifted");
						this.$el.style.setProperty("--shift", 0);
					}
				}
				horisontalShift = true;
				horisontalShiftChecked = false;
			})
		}
	},
	methods: {
		deleteDay() {
			axios.delete(store.state.apiRoot+"api/days/", {
				params: {
					date: this.day.toString('iso'),
				},
				headers: {
					'Content-Type': 'multipart/form-data',
					"accept": `application/json`,
					"Authorization": this.$store.state.jwt as string,	
				}
			}).then((res) => {
				if (res.data.status == 200) {
					this.deleteQuestionShown = false;
					this.$emit("dayDelete");
				}
			});
		},
		openImageFullscreen() {
			if (this.day.img && this.day.img.length!=0 && this.day.imgPaths[0].trim() != '') {
				this.$store.commit("displayFullscreenImages", this.day.imgPaths);
				return true;
			}
			return false;
		},
		toggleShown(name:String) {
			return /* this.togglesList.length == 0 ||  */this.togglesList.indexOf(name) != -1
		},
		emitHeadEvent(eventName:"undo"|"close", e:Event) {
			e.stopPropagation();
			this.$emit(eventName);
		},
	},
	watch: {
		day(newDay:DayClass, oldDay:DayClass) {
			if (newDay.date != oldDay.date) this.deleteQuestionShown = false;
		},
	},
	directives: {
		"body-p": {
			mounted(el, binding:any) {
				if (binding.instance.listItem && window.innerWidth <= 480 && el.offsetHeight >= 115.5) binding.instance.shownFull = false;
			}
		}
	}
})
