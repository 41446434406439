import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import {Day} from "@/classes/Day";
import {Month} from "@/classes/Month";
import {captions, CaptionElement} from "./Captions";
import SecureLS from 'secure-ls'
import { ColorPalette, StandardColorPalettes } from "@/classes/ColorPalette"

const ls = new SecureLS();

export enum Language {
	en = "en",
	ru = "ru",
	es = "es",
	de = "de",
	fr = "fr",
	it = "it",
	pt = "pt",
	fi = "fi",
}

export enum Theme { dark = "dark", light = "light" }

export interface State {
	jwt: String,
	userName: String,
	loading: Boolean,
	apiRoot: string,
	fullscreenImgArr: Array<any>,
	theme: Theme,
	lang: Language,
	colorPalette: ColorPalette,
	customColorPalette: ColorPalette|null,
	mobileWidth: Boolean,
	appMode: "web"|"mobile"
}

export const key: InjectionKey<Store<State>> = Symbol()

export default createStore<State>({
	state: {
		jwt: "",
		userName: "",
		loading: false,
		apiRoot: "https://amberday.net/",
		fullscreenImgArr: [],
		theme: Theme.light,
		lang: Language.en,
		colorPalette: StandardColorPalettes["amber"],
		customColorPalette: null,
		mobileWidth: window.innerWidth <= 480,
		appMode: "web",
	},
	getters: {
		caption: state => (captionName : keyof typeof captions, lang:Language = state.lang) : String|Array<String> => {
			return captions[captionName][lang];
		},
	},
	mutations: {
		displayFullscreenImages(state, arr:Array<any>) {
			state.fullscreenImgArr = arr;
		},
		hideFullscreenImages(state) {
			state.fullscreenImgArr = [];
		},
		switchTheme(state, theme?:Theme) {
			if (!theme) state.theme = state.theme == Theme.light ? Theme.dark : Theme.light
			else state.theme = theme;
			if (state.theme == Theme.dark) {
				document.body.classList.add("dark_theme");
			}
			else {
				document.body.classList.remove("dark_theme");
			}
			ls.set("theme", state.theme);
		},
		setLanguage(state, lang:Language) {
			state.lang = lang;
			ls.set("lang", lang);
		},
		setColorPalette(state, palette:ColorPalette) {
			state.colorPalette = palette;
			ls.set("colorPalette", JSON.stringify(palette));
		},
		checkScreenWidth(state) {
			state.mobileWidth = window.innerWidth <= 480;
		}
	},
	actions: {
	},
	modules: {
	}
});
