import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import LandingView from '../views/LandingView.vue'
import LoginView from '../views/LoginView.vue'
import CalendarView from '../views/CalendarView.vue'
import DayView from '../views/DayView.vue'
import DayDoneView from '../views/DayDoneView.vue'
import SearchView from '../views/SearchView.vue'
import SettingsView from '../views/SettingsView.vue'
import SettingsPaletteView from '../views/SettingsPaletteView.vue'
import axios from 'axios'
import store from '@/store'
import {Day} from "@/classes/Day"
import {Month} from "@/classes/Month"
import SecureLS from 'secure-ls'

const routes = [
	{
		path: '/',
		name: 'landing',
		component: LandingView
		// redirect: () => { return { name: 'login' } }
	},
	{
		path: '/img',
		name: 'landingImg',
		component: LandingView
		// redirect: () => { return { name: 'login' } }
	},
	{
		path: '/login',
		name: 'login',
		component: LoginView,
	},
	{
		path: '/calendar/:day/img',
		name: 'calendarImg',
		component: CalendarView,
	},
	{
		path: '/calendar/:day?',
		name: 'calendar',
		component: CalendarView,
		beforeEnter: (to:any, from:any, next:any) => {
			store.commit("hideFullscreenImages");
			next();
		}
	},
	{
		path: '/day/:date/img',
		name: 'dayImg',
		component: DayView,
	},
	{
		path: '/day/:date',
		name: 'day',
		component: DayView,
		beforeEnter: (to:any, from:any, next:any) => {
			store.commit("hideFullscreenImages");
			next();
		}
	},
	{
		path: '/day',
		name: 'dayRedirect',
		component: DayView,
		beforeEnter: async()=> {  // couldn't use redirect cause redirect can't be async
			let day = await findLastDayWithoutNote();
			router.push({'path': '/day/' + day.toString('iso')});
		}
	},
	{
		path: '/day/done/:rate',
		name: 'dayDone',
		component: DayDoneView,
		beforeEnter: (to:any,from:any)=> {
			if (from.name != 'day') router.replace({ name: 'dayRedirect' });
		}
	},
	{
		path: '/day/done',
		redirect: () => { return { name: 'dayRedirect' } }
	},
	{
		path: '/search',
		name: 'search',
		component: SearchView,
	},
	{
		path: '/settings',
		name: 'settings',
		component: SettingsView,
	},
	{
		path: '/settings/palette',
		name: 'settingsPalette',
		component: SettingsPaletteView,
	},
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
});

async function findLastDayWithoutNote() : Promise<Day> {
	let lastDayFound = false;
	let today = new Date();
	let currentMonth = await Month.load(today, true);
	while (!lastDayFound) {
		let startDay = currentMonth?.getDaysInMonth()!-1;
		if(today.getMonth() == currentMonth?.date.getMonth() && today.getFullYear() == currentMonth?.date.getFullYear()) {
			startDay = today.getDate()-1;
		}
		for (let i=startDay; i>=0; i--) {
			if (currentMonth?.days[i].isEmpty) return currentMonth?.days[i];
		}
		currentMonth = await Month.load(currentMonth!.getPrevMonthDate(), true);
	}

	return new Day(new Date());
}

function checkAuth() {
	if (store.state.jwt != "") return axios.get(store.state.apiRoot+"api/auth/validateJWT.php", {
		params: {
			jwt: store.state.jwt,
			crossDomain: store.state.appMode == "mobile",
			xhrFields: { withCredentials: true }
		}
	});
	else return axios.get(store.state.apiRoot+"api/auth/relogin.php", {
		params: {
			crossDomain: store.state.appMode == "mobile",
			xhrFields: { withCredentials: true }
		}
	});
}

router.beforeEach((to, from, next) => {
	let isLanding = to.path == '/' || to.path == '/img';

	if (isLanding && store.state.appMode == "mobile") next({name: 'login'});

	let ls = new SecureLS();
	console.log(ls.get('encryptionKey'), store.state.jwt != "" ? store.state.jwt : "no jwt");
	if (!ls.get('encryptionKey')) {
		console.log("no encryptionKey");
		if (!isLanding && to.path != '/login') next({name: "login"});
		else next();
		return;
	}

	checkAuth().then(res => {
		console.log(res.data, res.data.jwt, res.data.userName);
		if (!!res.data.jwt) {
			store.state.jwt = res.data.jwt;
		}
		if (!!res.data.userName) store.state.userName = res.data.userName;
		if (to.path == '/login') {
			if (!res.data.success) next();
			else next({name: "calendar"});
		}
		else {
			if (!res.data.success && !isLanding) next({name: "login"});
			else next();
		}
	})
});

export default router