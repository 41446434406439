import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c4e0895"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "inputs_container"
}
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["value", "disabled"]
const _hoisted_5 = {
  key: 0,
  class: "error_message"
}
const _hoisted_6 = {
  key: 1,
  class: "inputs_container"
}
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = ["disabled"]
const _hoisted_10 = {
  key: 0,
  class: "error_message"
}
const _hoisted_11 = {
  key: 1,
  class: "error_message"
}
const _hoisted_12 = {
  key: 2,
  class: "inputs_container"
}
const _hoisted_13 = { class: "text" }
const _hoisted_14 = { class: "select_wrapper" }
const _hoisted_15 = {
  value: "default",
  disabled: "",
  selected: ""
}
const _hoisted_16 = { value: "1" }
const _hoisted_17 = { value: "2" }
const _hoisted_18 = { value: "3" }
const _hoisted_19 = { value: "4" }
const _hoisted_20 = ["placeholder", "disabled"]
const _hoisted_21 = ["value", "disabled"]
const _hoisted_22 = {
  key: 3,
  class: "tip tip_login"
}
const _hoisted_23 = {
  key: 4,
  class: "tip tip_login"
}
const _hoisted_24 = {
  key: 5,
  class: "tip tip_login"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_first_input = _resolveDirective("first-input")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (_ctx.mode == 0 ? _ctx.submitLogin() : _ctx.submitSignup()), ["prevent"])),
    id: "form"
  }, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.mode == 0 ? _ctx.$store.getters.caption('login') : _ctx.$store.getters.caption('signup')), 1),
    (_ctx.mode==0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("input", {
            type: "email",
            placeholder: _ctx.$store.getters.caption('email'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginData.email) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.error=false)),
            required: "",
            name: "loginName"
          }, null, 40, _hoisted_2), [
            [_vModelText, _ctx.loginData.email],
            [_directive_first_input]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: _ctx.$store.getters.caption('password'),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.loginData.password) = $event)),
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.error=false)),
            required: "",
            name: "loginPass"
          }, null, 40, _hoisted_3), [
            [_vModelText, _ctx.loginData.password]
          ]),
          _createElementVNode("input", {
            type: "submit",
            value: _ctx.$store.getters.caption('loginButton'),
            disabled: _ctx.disabled
          }, null, 8, _hoisted_4),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$store.getters.caption('invalidCredentials')), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode==1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _withDirectives(_createElementVNode("input", {
            type: "email",
            name: "email",
            placeholder: _ctx.$store.getters.caption('email'),
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.signupData.email) = $event)),
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.checkUserExists && _ctx.checkUserExists(...args))),
            required: "",
            autocoplete: "off"
          }, null, 40, _hoisted_7), [
            [_vModelText, _ctx.signupData.email],
            [_directive_first_input]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            placeholder: _ctx.$store.getters.caption('password'),
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.signupData.password) = $event)),
            required: "",
            readonly: "",
            onfocus: "this.removeAttribute('readonly')"
          }, null, 8, _hoisted_8), [
            [_vModelText, _ctx.signupData.password]
          ]),
          _createElementVNode("button", {
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.continueSignup && _ctx.continueSignup(...args))),
            disabled: _ctx.disabled
          }, _toDisplayString(_ctx.$store.getters.caption('continueButton')), 9, _hoisted_9),
          (_ctx.userExists===true)
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$store.getters.caption('userExistsMessage')), 1))
            : (_ctx.error)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$store.getters.caption('registrationError')), 1))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode==2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$store.getters.caption('secretQuestions')), 1),
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.question) = $event)),
              onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.questionChange && _ctx.questionChange(...args)))
            }, [
              _createElementVNode("option", _hoisted_15, _toDisplayString(_ctx.$store.getters.caption('selectQuestion')), 1),
              _createElementVNode("option", _hoisted_16, _toDisplayString(_ctx.$store.getters.caption('firstBookQuestion')), 1),
              _createElementVNode("option", _hoisted_17, _toDisplayString(_ctx.$store.getters.caption('firstComputerGameQuestion')), 1),
              _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$store.getters.caption('firstDateQuestion')), 1),
              _createElementVNode("option", _hoisted_19, _toDisplayString(_ctx.$store.getters.caption('firstFriendNameQuestion')), 1)
            ], 544), [
              [_vModelSelect, _ctx.question]
            ])
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$store.getters.caption('answer'),
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.signupData.reserveKey) = $event)),
            onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.error=false)),
            required: "",
            readonly: "",
            onfocus: "this.removeAttribute('readonly')",
            disabled: _ctx.question=='default',
            id: "answer"
          }, null, 40, _hoisted_20), [
            [_vModelText, _ctx.signupData.reserveKey]
          ]),
          _createElementVNode("input", {
            type: "submit",
            value: _ctx.$store.getters.caption('registerButton'),
            disabled: _ctx.disabled
          }, null, 8, _hoisted_21)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode==0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createTextVNode(_toDisplayString(_ctx.$store.getters.caption('noAccountTip')) + " ", 1),
          _createElementVNode("span", {
            class: "link_style",
            onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.switchMode(1)))
          }, _toDisplayString(_ctx.$store.getters.caption('signUpLink')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode==1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
          _createTextVNode(_toDisplayString(_ctx.$store.getters.caption('haveAccountTip')) + " ", 1),
          _createElementVNode("span", {
            class: "link_style",
            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.switchMode(0)))
          }, _toDisplayString(_ctx.$store.getters.caption('loginLink')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.mode==2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("span", {
            class: "link_style",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.switchMode(1)))
          }, _toDisplayString(_ctx.$store.getters.caption('goBackTip')), 1)
        ]))
      : _createCommentVNode("", true)
  ], 32))
}