import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "lang noselect" }
const _hoisted_2 = {
  key: 0,
  class: "lang_popup"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.langPopupShown = !_ctx.langPopupShown)),
      class: ""
    }, _toDisplayString(_ctx.$store.state.lang.toUpperCase()), 1),
    (_ctx.langPopupShown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.langArray(), (lang) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "lang_popup_elem",
              onClick: ($event: any) => (_ctx.langPicked(lang))
            }, _toDisplayString(_ctx.$store.getters.caption("langName", lang)), 9, _hoisted_3))
          }), 256))
        ]))
      : _createCommentVNode("", true)
  ]))
}