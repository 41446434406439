
import { defineComponent } from 'vue';
import router from '@/router';
import store from '@/store';
import Header from "@/components/Header.vue";
import Month from "@/components/Month.vue";
import Day from "@/components/Day.vue";
import DayNote from "@/components/DayNote.vue";
import MonthViewer from "@/components/MonthViewer.vue";
import PalettePicker from "@/components/PalettePicker.vue";
import {Day as DayClass} from "../classes/Day";
import {Month as MonthClass} from "../classes/Month";
import { MonthBuffer } from '@/classes/MonthBuffer';
import {Year} from "../classes/Year";
import { type Swiper as SwiperRef } from 'swiper';
import { ColorPalette, StandardColorPalettes, IRGBColor } from "@/classes/ColorPalette"
import { ColorPicker } from 'vue-accessible-color-picker'

export default defineComponent({
	data: () => {
		return {
			dayNoteClasses: [] as String[],
			prevViewedDayHadImg: null as Boolean|null,  // note: it's null if no day is viewed
			viewedDay: null as DayClass|null,
			monthBuffer: null as MonthBuffer|null,
			monthPickMode: "days" as "days"|"months",

			calendarMode: "month" as "month"|"year",
			year: null as Year|null,
			yearBuffer: new Map<number, Year>() as Map<number, Year>,

			palettePickerShown: false,
		}
	},
	components: { Header, Month, Day, DayNote, MonthViewer, PalettePicker },
	computed: {
		mainClasses() {
			let arr = [];
			arr.push(this.calendarMode == 'month' ? 'calendar_mode_month' : 'calendar_mode_year');
			return arr;
		},
	},
	beforeCreate() {
        document.getElementsByTagName('html')[0].classList.add('noscroll');
	},
	beforeUnmount() {
        document.getElementsByTagName('html')[0].classList.remove('noscroll');
	},
	async mounted() {
		if (!store.state.jwt || store.state.jwt.trim() == "") router.replace("login");

		if (!!this.$route.params.day) {
			let dateStr = this.$route.params.day.toString();
			let date = new Date(dateStr);
			this.monthBuffer = await MonthBuffer.create(date);
			this.viewedDay = this.monthBuffer.months[this.monthBuffer.loadingRange]!.days[date.getDate()-1];
		}
		else {
			this.monthBuffer = await MonthBuffer.create(new Date());
		}
	},
	methods: {
		dayNoteOpened(day: DayClass) {
			router.replace({ name: router.currentRoute.value.name!, params: { day: day.toString('iso') } });
			this.viewedDay = day;
		},
		openDayNoteFromYear(day: DayClass) {
			let monthIndex = day.date.getMonth();
			// let yearMonthEl = document.querySelectorAll(".year_view .month")[monthIndex] as HTMLElement;
			// let innerEl = document.querySelector("main .inner") as HTMLElement;

			this.monthBuffer = new MonthBuffer(this.year!.monthClones(), MonthBuffer.loadingRangeDefault, monthIndex);
			// this.monthBuffer.performWhenSwiperLoaded((sw) => sw.slideTo(monthIndex, 0, false));

			// innerEl.style.setProperty("--year-month-slide-initial-x", yearMonthEl.getBoundingClientRect().left + "px");
			// innerEl.style.setProperty("--year-month-slide-initial-y", yearMonthEl.getBoundingClientRect().top + "px");
			// innerEl.style.setProperty("--year-month-slide-initial-width", (yearMonthEl.offsetWidth-15)+"px");
			// innerEl.style.setProperty("--year-month-slide-final-x", innerEl.offsetLeft + "px");
			// innerEl.style.setProperty("--year-month-slide-final-y", /* (innerEl.offsetTop + 40) +  */"196px");

			this.viewedDay = day.clone();

			window.scrollTo({
				top: 0,
				behavior: 'instant' as unknown as ScrollBehavior
			});
		},
		dayNoteClosed(animated=true) {  // да, я знаю про вьюшные transition. здесь они, почему-то работают не так плавно, что странно.
			router.replace(`/calendar`);
		},

		async switchCalendarMode(mode:"year"|"month") {
			if (mode == "year")
				await this.showYear(this.monthBuffer!.shownMonth()!.date.getFullYear());
			this.calendarMode = mode;
		},
		async yearArrowClick(shift:-1|1) {
			if (this.year == null) return;
			await this.showYear(this.year.year+shift);
		},
		async showYear(year:number) {
			if (this.yearBuffer.has(year))
				this.year = this.yearBuffer.get(year)!;
			else {
				this.year = await Year.create(year);
				this.yearBuffer.set(year, this.year as Year);
			}
		},
	},
	directives: {}
})
