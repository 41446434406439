
import { defineComponent } from "vue";
import axios from 'axios';
import router from './router';
import store from './store';
import "@/assets/general.scss";
import Header from "@/components/Header.vue"
import LoadingIcon from "@/components/LoadingIcon.vue"
import ImgFullscreen from "@/components/ImgFullscreen.vue"
import BottomNavBar from "@/components/BottomNavBar.vue"
import SecureLS from 'secure-ls'
import { ColorPalette, StandardColorPalettes } from "@/classes/ColorPalette"
var CryptoJS = require("crypto-js")

export default defineComponent({
	components: { Header, LoadingIcon, ImgFullscreen, BottomNavBar },
	data: ()=> {
		return {
			noHeaderPages: [ "login", "landing", "landingImg" ],
			noBottomNavBarPages: [ "login", "landing", "landingImg" ],
			transitionName: 'other'
		}
	},
	beforeMount() {
		let ls = new SecureLS();

		if (!ls.get("theme")) {
			const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
			if (darkThemeMq.matches) {
				store.commit("switchTheme", "dark");
			}
		}
		else {
			store.commit("switchTheme", ls.get("theme"));
		}

		if (!ls.get("lang")) {
			if (navigator.language) {
				if (/^ru\b/.test(navigator.language)) {
					store.commit("setLanguage", "ru");
					ls.set("lang", "ru");
				}
			}
		}
		else {
			store.commit("setLanguage", ls.get("lang"));
		}
		
		let palette = ls.get("colorPalette");
		if (palette) {
			store.commit("setColorPalette", ColorPalette.fromObject(JSON.parse(palette)));
			if (store.state.colorPalette.name == "Custom") {
				store.state.customColorPalette = ColorPalette.fromObject(JSON.parse(palette));
			}
		}

		if (store.state.appMode == "web") store.state.apiRoot = "/";
	},
	mounted() {
		window.addEventListener("resize", () => store.commit("checkScreenWidth"))
	},
	watch: {
		$route(to, from) {
			this.transitionName = to.name == 'dayDone' ? 'day_done' : 'other';
		}
	}
})
