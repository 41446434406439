
import { defineComponent } from 'vue';
import axios from 'axios';
import router from '@/router';
import store from "@/store"
import Header from "@/components/Header.vue";
import Month from "@/components/Month.vue";
import Day from "@/components/Day.vue";
import LoadingIcon from "@/components/LoadingIcon.vue"
import DayNote from "@/components/DayNote.vue";
import {Day as DayClass} from "../classes/Day";
import {Month as MonthClass} from "../classes/Month";
import Evaluator from "../classes/Evaluator"
import { MonthBuffer } from '@/classes/MonthBuffer';
import FormData from "form-data";
import imageCompression from 'browser-image-compression';
import SecureLS from 'secure-ls'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
var CryptoJS = require("crypto-js")

export default defineComponent({
	data: () => {
		return {
			mode: 0 as 0|1,  //  0 - creating, 1 - editing

			pageLoading: true as boolean,

			day: new DayClass(new Date(), undefined, "") as DayClass,

			evaluator: new Evaluator(new DayClass(new Date(), undefined, "")) as Evaluator,

			editing: {
				initialDay: new DayClass(new Date()) as DayClass,
				reevaluated: false as boolean,
				/* newEvaluationRange: {  // it's used in case he reevaluates, cancels reevaluation, and then cancels the reevaluation cancelling
					start: null as number|null,
					end: null as number|null,
				}, */
			},

			datePicker: {
				monthBuffer: null as null|MonthBuffer,
				shown: false as Boolean
			},

			maxImagesCount: 5 as number,
			imageUrls: [] as string[],
			images: [] as File[],
			imageCountError: 0 as number,
			compressOptions: {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true,
			},
			cropperShown: false as Boolean,
		}
	},
	components: { Header, Month, Day, DayNote, LoadingIcon, Cropper },
	computed: {
		dateSent():String {
			let ds = this.mode == 0 ? this.day.toString('iso') : this.editing.initialDay.toString('iso');
			this.evaluator.dateSent = ds;
			return ds;
		}
	},
	methods: {
		// date picker
		async alterDatePicker() {
			if (this.datePicker.monthBuffer == null && !this.datePicker.shown)
				this.datePicker.monthBuffer = await MonthBuffer.create(this.day?.date!);
			this.datePicker.shown = !this.datePicker.shown;
		},
		changeDay(pickedDay:DayClass) {
			if (!this.datePicker.monthBuffer || !this.datePicker.monthBuffer?.swiper) return;
			// isEmpty condition is supplemented by an additional one cause when editing, you wanna be able to switch back to initial date with no problem
			if ((pickedDay.isEmpty || this.mode==1 && this.editing.initialDay.date.getTime()==pickedDay.date.getTime()) && pickedDay.date <= new Date()) {
				this.datePicker.shown = false;
				if (this.mode == 0) {
					router.replace(`/day/${pickedDay.toString('iso')}`);
				}
				else {
					this.day.date = pickedDay.date;
				}
			}
		},
		pickerPickModeChange(newPickMode:"days"|"months") {
			let pickerEl = document.getElementsByClassName("date_picker")[0] as HTMLElement;
			if (newPickMode == "months") pickerEl.style.minHeight = pickerEl.offsetHeight+"px";
			else pickerEl.style.minHeight = "auto";
		},

		checkImageCount(e:Event) {
			if (this.imageUrls.length < this.maxImagesCount) {
				document.getElementById("attach")?.click();
			}
			else this.imageCountError++;
		},
		async onFileChange(e:any) {
			this.$store.state.loading = true;
			let initialImgCount = this.images.length;
			if (this.images.length < this.maxImagesCount) {
				let allowedCount = this.maxImagesCount - this.images.length;
				if (allowedCount < e.target.files.length) this.imageCountError++;
				for (let i=0; i<Math.min(e.target.files.length, allowedCount); i++) {
					let compressedImg = await imageCompression(e.target.files[i], this.compressOptions);
					this.images.push(compressedImg);
					this.imageUrls.push(URL.createObjectURL(compressedImg));
					URL.revokeObjectURL(e.target.files[i]);
					if (initialImgCount==0) this.cropperShown = true;
				}
				e.target.value = "";
			}
			this.$store.state.loading = false;
		},
		deleteImage(i:number) {
			this.images.splice(i,1);
			this.imageUrls.splice(i,1);
		},
		cropChange({ coordinates, canvas }:any) {
			console.log(coordinates, canvas);
		},

		// evaluation
		async initializeComparison(estimate: -1|0|1) {
			this.evaluator.initializeComparison(estimate);
		},
		backToSubjective() {
			this.evaluator.backToSubjective()
			this.day.rate = undefined;
			this.editing.reevaluated = false;
		},
		async dayCompared(comparison: -1|1) {
			this.evaluator.dayCompared(comparison);
		},
		finishEvaluation() {
			this.evaluator.finishEvaluation();
			if (this.mode == 1) this.editing.reevaluated = true;
		},
		undoEvaluationStep() {
			this.evaluator.undoEvaluationStep();
		},
		cancelReevaluation() {
			this.backToSubjective();
			this.day.rate = this.editing.initialDay.rate;
			this.evaluator.evaluationState = 2;
			this.editing.reevaluated = false;
		},

		save() {
			let formData = new FormData();
			let ls = new SecureLS();
			let key = ls.get("encryptionKey");
			for (let i = 0; i < this.images.length; i++){
				formData.append(`img[${i}]`, this.images[i], this.images[i].name);
			}
			let note = this.day.note?.replace(/\n{3,}/, "\n\n");
			formData.append("note", CryptoJS.AES.encrypt(note, key).toString());
			formData.append("date", this.dateSent);
			if (this.mode == 0 || this.editing.reevaluated) {
				formData.append("range[0]", this.evaluator.evaluationRange.start);
				formData.append("range[1]", this.evaluator.evaluationRange.end);
				formData.append("estimate", this.evaluator.estimate);
				formData.append("lastComparison", this.evaluator.lastComparison);
			}
			if (this.mode == 1) {
				if (this.editing.reevaluated) formData.append("reevaluated", 1);
				if (this.day.toString("iso") != this.editing.initialDay.toString("iso")) {
					formData.append("newDate", this.day.toString('iso'));
				}
				if (this.day.img.length > 0) {
					formData.append("oldImages", JSON.stringify(this.day.img));
				}
			}

			axios.post(store.state.apiRoot+"api/days/", formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					"accept": `application/json`,
					"Authorization": this.$store.state.jwt as string,	
				}
			}).then((res) => {
				// console.log(res.data);
				if (res.data.status == 200) {
					// this.$router.push({path: `/day/done/${res.data.rate}`});
					this.$router.push({path: `/calendar/${this.dateSent}`});
				}
				else if (res.data.status == 403) {
					this.$router.push({name: "login"});
				}
			})
		}
	},
	async beforeMount() {
		let fullPath = router.currentRoute.value.fullPath;
		if (fullPath.match(/\/img\/?/)) {
			router.replace(fullPath.replace(/\/img\/?/, ""));
		}

		this.evaluator = new Evaluator(this.day as DayClass);
		
		this.pageLoading = true;
		let dateStr = this.$route.params.date.toString();
		let day = await DayClass.load({date: dateStr});
		if (day != null) {
			this.evaluator.evaluationState = 2;
			this.day = day.clone();
			this.editing.initialDay = day.clone();
			
			for (let i in day.img) {
				const response = await fetch(this.$store.state.apiRoot+"img/days/"+day.img[i]+"?nocache-0");
				const data = await response.blob();
				const ext = day.imgPaths[0].split('.').pop();
				const metadata = { type: `image/${ext}` };
				this.images.push(new File([data], day.imgPaths[i] as string, metadata));
				this.imageUrls.push(this.$store.state.apiRoot+"img/days/"+day.img[i]+"?nocache-1");
			}
			this.mode = 1;
		}
		else {
			this.mode = 0;
			this.day = new DayClass(DayClass.dateFromString(dateStr), undefined, "");
		}
		this.pageLoading = false;
	},
	created() {
		this.$watch(() => this.$route.params, (toParams:any, previousParams) => {
				if(!!toParams.date && this.mode == 0) this.day.date = DayClass.dateFromString(toParams.date.toString());
			}
		)
	},
	directives: {
		"date-picker": {
			mounted: function (el, binding:any, vnode) {
				el.clickOutsideEvent = function (e:Event) {
					let addedDate = document.querySelector(".picked_date")!;
					let t = e.target as Node;
					if (!(el == t || el.contains(t)) && !(addedDate == t || addedDate.contains(t))) {
						if (binding.instance.datePicker.shown) binding.instance.datePicker.shown = false;
					}
				};
				document.body.addEventListener('click', el.clickOutsideEvent)
				// setTimeout(() => el.style.minHeight = el.offsetHeight+"px", 200);
			},
			unmounted: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent)
			}
		}
	}
})
