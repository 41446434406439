
import { defineComponent } from 'vue';
import axios from 'axios';
import router from '@/router';
import DayNote from "@/components/DayNote.vue";
import {Day as DayClass} from "../classes/Day";
import {DayCollection} from "../classes/DayCollection";
import Fuse from 'fuse.js';

export default defineComponent({
	components: { DayNote },
	data: () => {
		return {
			searchPhrase: "",
			result: [] as any,
			searchCounter: 0 as number,
		}
	},
	mounted() {

	},
	methods: {
		async search() {
			if (this.searchPhrase.trim() == "") {
				this.result = [];
				return;
			}
			let unfiltered = await DayCollection.load();
			if (unfiltered == null) return;
			let fuse = new Fuse(unfiltered.days, {
				ignoreLocation: true,
				threshold: 0.2,
				includeMatches: true,
				includeScore: true,
				minMatchCharLength: this.searchPhrase.length-1,
				shouldSort: false,
				keys: [ "note" ]
			});
			this.result = fuse.search(this.searchPhrase);
			this.result = this.result.sort((day1:Fuse.FuseResult<DayClass>, day2:Fuse.FuseResult<DayClass>) => day2.item.date.getTime()-day1.item.date.getTime());
			this.searchCounter++;
		},
		getHighlightIndices(indices:Array<Array<number>>) {
			return indices.reduce((maxRange, val) => maxRange[1]-maxRange[0] > val[1]-val[0] ? maxRange : val);
		}
	}
})
