import {Day as Day, IDay} from "./Day";
import axios from 'axios';
import router from '@/router';
import store from '@/store'

export interface IDayCollection {
	days: Array<IDay>
}

export class DayCollection {
	days!: Array<Day>;

	constructor(days: Array<Day> = []) {
		this.days = days;
	}

	static fromObject(obj: IDayCollection, decryptNotes:Boolean=false):DayCollection {
		let daysCopy: Array<Day> = [];
		for(let i in obj.days) {
			if (obj.days[i] != null) daysCopy.push(Day.fromObject(obj.days[i], decryptNotes) as Day);
		}
		return new DayCollection(daysCopy);
	}
	
	static async load(params: Object = {}) : Promise<DayCollection|null> {
		store.state.loading = true;
		
		let returnVal = await axios.get(store.state.apiRoot+"api/days/", {
			params: params,
			headers: {
				"accept": "application/json",
				"Authorization": `${store.state.jwt}`,
			}
		})
		.then((res) => {
			console.log(res.data);
			store.state.loading = false;
			if (res.data.status == 200) {
				if (!!res.data.jwt) store.state.jwt = res.data.jwt;
				if (!!res.data.day) return DayCollection.fromObject({days: [res.data.day]}, true);
				if (!!res.data.days) return DayCollection.fromObject({days: res.data.days}, true);
			}
			else if (res.data.status == 403) {
				router.push({path: "/login"});
			}
			return null;
		});

		return returnVal;
	}
}