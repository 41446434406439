
import { defineComponent } from "vue";
import store from '@/store';
import router from '@/router';
import "@/assets/general.scss";
import "@/assets/vars.scss";

export default defineComponent({
	methods: {
		activeClass(pageName:string):string {
			return router.currentRoute.value.fullPath.indexOf(pageName) != -1 ? "active" : "";
		}
	}
})
