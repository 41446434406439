
import { defineComponent } from "vue";
import store from '@/store';
import router from '@/router';
import "@/assets/general.scss";
import "@/assets/vars.scss";
import {Day, Day as DayClass} from "@/classes/Day";
import {Month as MonthClass} from "@/classes/Month";
import PalettePicker from "@/components/PalettePicker.vue";
import Month from "@/components/Month.vue";
import { ColorPalette, StandardColorPalettes, IRGBColor } from "@/classes/ColorPalette"

export default defineComponent({
	data() {
		return {
			sampleMonth: new MonthClass(new Date()) as MonthClass
		}
	},
	components: { PalettePicker, Month },
	mounted() {
		let daysCount = this.sampleMonth.getDaysInMonth(),
			daysArr:Array<DayClass> = [],
			filledDaysCount = 10,
			fraction = 1/filledDaysCount;
		for (let i=0; i<filledDaysCount; i++) {
			let repeatedVal = true;
			let rndDate = -1;
			while (repeatedVal) {
				rndDate = Math.ceil(Math.random()*daysCount);
				repeatedVal = daysArr.find(d => d.date.getDate() == rndDate) !== undefined;
			}
			let pushedDate:Date = MonthClass.fixDate(new Date());
			pushedDate.setDate(rndDate);
			daysArr.push(new Day(pushedDate, store.state.colorPalette.gradientFunction(fraction*i) as number));
		}
		this.sampleMonth.days = daysArr;
		this.sampleMonth.fillGaps();
	},
	beforeCreate() {
        document.getElementsByTagName('html')[0].classList.add('noscroll');
	},
	beforeUnmount() {
        document.getElementsByTagName('html')[0].classList.remove('noscroll');
	},
	methods: {
		paletteClose() {
			// router.remove('')
		}
	},
	directives: {
		"palette-picker": {
			mounted(el) {
				let monthEl = document.getElementsByClassName("month")[0] as HTMLElement;
				el.style.setProperty("--top", monthEl.offsetHeight+monthEl.offsetTop+20+"px");
				el.style.height = (document.documentElement.clientHeight - el.getBoundingClientRect().top)+"px";
				el.style.setProperty("--height", el.offsetHeight+"px");
			},
		}
	}
})
